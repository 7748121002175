import React from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const renderSkeleton = () => (
  <div className="card card-bordered border-gray-700 card-stretch-75">
    <div className="card-header border-gray-700 p-5 d-block ribbon ribbon-top">
      {/* <div className="ribbon-label bg-secondary">
        <Skeleton width={150} className="bg-secondary"/>
      </div> */}
      <div className="card-title fs-3 p-2">
        <Skeleton height={40} width={300}/>
      </div>
    </div>
    <div className="card-body bg-transparent">
      <Skeleton height={40} count={5} />
    </div>
    <div className="card-footer d-flex flex-end">
      <div className="d-flex flex-row-fluid flex-start">
        <div className="m-2 hover-scale">
          <Skeleton width={100} height={40} />
        </div>
      </div>
      <Skeleton width={100} height={40} />
    </div>
  </div>
);

export default renderSkeleton;
