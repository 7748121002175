import React, { useState } from 'react';
import { profileUpdate } from 'features/auth';
import { useSelector, useDispatch } from 'react-redux';

const Profile = () => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('aperçu');
    const [updated, setUpdated] = useState(false);
    const { loading, user } = useSelector((state) => state.user);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        faculty: "",
        city: "",
        communication: [],
        image: null,
    });

    const onInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        // Update the form data based on the input field type
        if (type === "checkbox") {
            // Handle checkboxes (communication array)
            setFormData((prevFormData) => ({
                ...prevFormData,
                communication: checked
                    ? [...prevFormData.communication, value]
                    : prevFormData.communication.filter((item) => item !== value),
            }));
        } else {
            // Handle other input fields
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(profileUpdate(formData));
        setUpdated(true);
    };

    return (
        <>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <div className="me-7 mb-4">
                            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                <img src="/assets/media/svg/avatars/blank.svg" alt="boy" />
                                <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                            </div>
                        </div>
                        <div className="flex-grow-1 p-3">
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-2">
                                        <span className="text-gray-900 fs-2 fw-bold me-1 text-capitalize">{user.name}</span>
                                        {user && user.profile && user.profile.is_verified &&
                                            <span>
                                                <i className="ki-duotone ki-verify fs-1 text-primary">
                                                    <span className="path1"></span>
                                                    <span className="path2"></span>
                                                </i>
                                            </span>
                                        }
                                    </div>
                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                        <span className="d-flex align-items-center text-gray-400 me-5 mb-2">
                                            <i className="ki-duotone ki-profile-circle fs-4 me-1">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                            </i>Medecin</span>
                                        {user && user.profile && user.profile.city &&
                                            <span className="d-flex align-items-center text-gray-400 me-5 mb-2">
                                                <i className="ki-duotone ki-geolocation fs-4 me-1">
                                                    <span className="path1"></span>
                                                    <span className="path2"></span>
                                                </i>{user.profile.city}</span>
                                        }
                                        <span className="d-flex align-items-center text-gray-400 mb-2">
                                            <i className="ki-duotone ki-sms fs-4 me-1">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                            </i>{user.email}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                        <li className="nav-item mt-2">
                            <a
                                className={`nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 'aperçu' ? 'active' : ''}`}
                                href="#"
                                onClick={() => handleTabClick('aperçu')}
                            >
                                Aperçu
                            </a>
                        </li>
                        <li className="nav-item mt-2">
                            <a
                                className={`nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 'paramètres' ? 'active' : ''}`}
                                href="#"
                                onClick={() => handleTabClick('paramètres')}
                            >
                                Paramètres
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {activeTab === 'aperçu' &&
                <div className="card mb-5 mb-xl-10">
                    <div className="card-header cursor-pointer">
                        <div className="card-title m-0">
                            <h3 className="fw-bold m-0">Détails du profil</h3>
                        </div>
                        <a href="#" onClick={() => handleTabClick('paramètres')} className="btn btn-sm btn-primary align-self-center">Edit Profile</a>
                    </div>
                    <div className="card-body p-9">
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Nom et prénom</label>
                            {user && user.profile && user.profile.firstName &&
                                <div className="col-lg-8">
                                    <span className="fw-bold fs-6 text-gray-800">{user.profile.firstName} {user.profile.lastName}</span>
                                </div>
                            }
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Numéro du contact
                                <span className="ms-1" data-bs-toggle="tooltip" title="Le numéro de téléphone doit être actif">
                                    <i className="ki-duotone ki-information fs-7">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span></label>
                            {user && user.profile && user.profile.phone &&
                                <div className="col-lg-8 d-flex align-items-center">
                                    <span className="fw-bold fs-6 text-gray-800 me-2">{user.profile.phone}</span>
                                </div>
                            }
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Faculté</label>
                            {user && user.profile && user.profile.faculty &&
                                <div className="col-lg-8">
                                    <a href="#" className="fw-semibold fs-6 text-gray-800 text-hover-primary">{user.profile.faculty}</a>
                                </div>
                            }

                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Ville
                                <span className="ms-1" data-bs-toggle="tooltip" title="Ville d'origine">
                                    <i className="ki-duotone ki-information fs-7">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span></label>
                            {user && user.profile && user.profile.city &&
                                <div className="col-lg-8">
                                    <span className="fw-bold fs-6 text-gray-800">{user.profile.city}</span>
                                </div>
                            }

                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Communication</label>
                            <div className="col-lg-8">
                                <span className="fw-bold fs-6 text-gray-800">E-mail, téléphone</span>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {activeTab === 'paramètres' &&
                <div className="card mb-5 mb-xl-10">
                    <div className="card-header border-0 cursor-pointer">
                        <div className="card-title m-0">
                            <h3 className="fw-bold m-0">Détails du profil</h3>
                        </div>
                    </div>
                    <div className="collapse show">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body border-top p-9">

                                <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">Nom et prénom</label>
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-6 fv-row">
                                                <input required type="text" name='firstName' className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Prénom" onChange={onInputChange} />
                                            </div>
                                            <div className="col-lg-6 fv-row">
                                                <input required type="text" name='lastName' className="form-control form-control-lg form-control-solid" placeholder="Nom" onChange={onInputChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                        <span className="required">Numéro du contact</span>
                                        <span className="ms-1" data-bs-toggle="tooltip" title="Le numéro de téléphone doit être actif">
                                            <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                            </i>
                                        </span>
                                    </label>
                                    <div className="col-lg-8 fv-row">
                                        <input required type="tel" name='phone' className="form-control form-control-lg form-control-solid" placeholder="Numéro du contact" onChange={onInputChange} />
                                    </div>
                                </div>
                                <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label fw-semibold fs-6">Faculté</label>
                                    <div className="col-lg-8 fv-row">
                                        <select required name='faculty' aria-label="Choisissez une faculté" data-control="select2" data-placeholder="Choisissez une faculté..." className="form-select form-select-solid form-select-lg fw-semibold" onChange={onInputChange}>
                                            <option value="">Choisissez une faculté...</option>
                                            <option value="Faculté de médecine Constantine">Faculté de médecine Constantine</option>
                                            <option value="Faculté de médecine Alger">Faculté de médecine Alger</option>
                                            <option value="oran">Faculté de médecine Oran</option>
                                            <option value="Faculté de médecine Oran">Faculté de médecine Annaba</option>
                                            <option value="tizi-ouzou">Faculté de médecine TiZi-OUzou</option>
                                            <option value="Faculté de médecine TiZi-OUzou">Faculté de médecine Blida</option>
                                            <option value="Faculté de médecine Batna">Faculté de médecine Batna</option>
                                            <option value="Faculté de médecine Tlemcen">Faculté de médecine Tlemcen</option>
                                            <option value="Faculté de médecine Sidi Bel Abbès">Faculté de médecine Sidi Bel Abbès</option>
                                            <option value="Faculté de médecine Sétif">Faculté de médecine Sétif</option>
                                            <option value="Faculté de médecine Bejaia">Faculté de médecine Bejaia</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                        <span className="required">Ville</span>
                                        <span className="ms-1" data-bs-toggle="tooltip" title="Ville d'origine">
                                            <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                            </i>
                                        </span>
                                    </label>
                                    <div className="col-lg-8 fv-row">
                                        <select required name="city" aria-label="Selectionner une ville" data-control="select2" data-placeholder="Selectionner une ville..." className="form-select form-select-solid form-select-lg fw-semibold" onChange={onInputChange}>
                                            <option value="">Selectionner une ville...</option>
                                            <option value="adrar">Adrar</option>
                                            <option value="chlef">Chlef</option>
                                            <option value="laghouat">Laghouat</option>
                                            <option value="oum-el-bouaghi">Oum El Bouaghi</option>
                                            <option value="batna">Batna</option>
                                            <option value="bejaia">Béjaïa</option>
                                            <option value="biskra">Biskra</option>
                                            <option value="bechar">Béchar</option>
                                            <option value="blida">Blida</option>
                                            <option value="bouira">Bouira</option>
                                            <option value="tamanrasset">Tamanrasset</option>
                                            <option value="tebessa">Tébessa</option>
                                            <option value="tlemcen">Tlemcen</option>
                                            <option value="tiaret">Tiaret</option>
                                            <option value="tizi-ouzou">Tizi Ouzou</option>
                                            <option value="alger">Alger</option>
                                            <option value="djelfa">Djelfa</option>
                                            <option value="jijel">Jijel</option>
                                            <option value="setif">Sétif</option>
                                            <option value="saida">Saïda</option>
                                            <option value="skikda">Skikda</option>
                                            <option value="sidi-bel-abbes">Sidi Bel Abbès</option>
                                            <option value="annaba">Annaba</option>
                                            <option value="guelma">Guelma</option>
                                            <option value="constantine">Constantine</option>
                                            <option value="medea">Médéa</option>
                                            <option value="mostaganem">Mostaganem</option>
                                            <option value="msila">M'Sila</option>
                                            <option value="mascara">Mascara</option>
                                            <option value="ouargla">Ouargla</option>
                                            <option value="oran">Oran</option>
                                            <option value="el-bayadh">El Bayadh</option>
                                            <option value="illizi">Illizi</option>
                                            <option value="bordj-bou-arreidj">Bordj Bou Arréridj</option>
                                            <option value="boumerdes">Boumerdès</option>
                                            <option value="el-tarf">El Tarf</option>
                                            <option value="tindouf">Tindouf</option>
                                            <option value="tissemsilt">Tissemsilt</option>
                                            <option value="el-oued">El Oued</option>
                                            <option value="khenchela">Khenchela</option>
                                            <option value="souk-ahras">Souk Ahras</option>
                                            <option value="tipaza">Tipaza</option>
                                            <option value="mila">Mila</option>
                                            <option value="ain-defla">Aïn Defla</option>
                                            <option value="naama">Naâma</option>
                                            <option value="ain-temouchent">Aïn Témouchent</option>
                                            <option value="ghardaia">Ghardaïa</option>
                                            <option value="relizane">Relizane</option>
                                        </select>

                                    </div>
                                </div>
                                <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">Communication</label>
                                    <div className="col-lg-8 fv-row">
                                        <div className="d-flex align-items-center mt-3">
                                            <label className="form-check form-check-custom form-check-inline form-check-solid me-5">
                                                <input className="form-check-input" name="communication" type="checkbox" value="E-mail" onChange={onInputChange} />
                                                <span className="fw-semibold ps-2 fs-6">E-mail</span>
                                            </label>
                                            <label className="form-check form-check-custom form-check-inline form-check-solid">
                                                <input className="form-check-input" name="communication" type="checkbox" value="téléphone" onChange={onInputChange} />
                                                <span className="fw-semibold ps-2 fs-6">téléphone</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-footer d-flex justify-content-end py-6 px-9">
                                {updated &&
                                    <div className="alert alert-dismissible bg-light-success border-success border-dashed d-flex flex-column flex-sm-row p-5">
                                        <i className="ki-duotone ki-message-notif fs-2hx text-success me-4 mb-5 mb-sm-0">
                                            <span className="path1"></span><span className="path2"></span><span className="path3"></span> <i className="path4"></i>
                                            <i className="path5"></i></i>

                                        <div className="d-flex flex-column pe-0 pe-sm-10">
                                            <h5 className="mb-1">Info</h5>

                                            <span>Votre profil a été mis à jour avec succès</span>
                                        </div>

                                        <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" onClick={() => setUpdated(false)}>
                                            <i className="ki-duotone ki-cross fs-1 text-success"><span className="path1"></span><span className="path2"></span></i>
                                        </button>
                                    </div>
                                }
                                <div className="d-flex flex-column flex-sm-row p-5">
                                    {loading ? (
                                        <button disabled className="btn btn-primary">
                                            <span className="">S'il vous plaît, attendez...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                        </button>
                                    ) : (
                                        <button type="submit" className="btn btn-primary">Enregistrer les modifications</button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }
        </>

    );
};

export default Profile;