import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Statistics = () => {
  const { statistics } = useSelector((state) => state.medecine);
  const labels = ["Faux", "justes", "Part/ justes"];
  const [performanceData, setPerformanceData] = useState(Array(12).fill(0));
  const [answersData, setAnswersData] = useState(Array(12).fill(0));
  const [categories, setCategories] = useState([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [completionPercentage, setCompletionPercentage] = useState(0);

  useEffect(() => {
    if (statistics && statistics.monthly_statistics) {
      const progPercentage = (
        (statistics.answers_count / statistics.questions_count) *
        100
      ).toFixed(0);
      setProgressPercentage(progPercentage);

      const compPercentage = (
        (statistics.completed_modules_count / statistics.modules_count) *
        100
      ).toFixed(0);
      setCompletionPercentage(compPercentage);

      const firstMonth = statistics.monthly_statistics[0]?.Month;
      if (firstMonth) {
        const monthIndex = categories.indexOf(firstMonth);
        const reorderedCategories = [
          ...categories.slice(monthIndex),
          ...categories.slice(0, monthIndex),
        ];
        const updatedPerformanceData = Array(12).fill(0);
        const updatedAnswersData = Array(12).fill(0);

        for (const item of statistics.monthly_statistics) {
          const index = reorderedCategories.indexOf(item.Month);
          updatedPerformanceData[index] = item.Performance;
          updatedAnswersData[index] = item["Number of Answers"];
        }
        setCategories(reorderedCategories);
        setPerformanceData(updatedPerformanceData);
        setAnswersData(updatedAnswersData);
      }
    }
  }, [statistics]);

  const data = [
    statistics && statistics.wrong_count,
    statistics && statistics.true_count,
    statistics && statistics.part_true_count,
  ];

  const AnswersOptions = {
    series: data,
    labels: labels,

    colors: ["#F44336", "#4CAF50", "#FF9800"],
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  };

  // ApexCharts configuration
  const options = {
    chart: {
      type: "bar", // Set the chart type to "bar" for a combination chart
      stacked: false,
      height: 350,
      fontFamily: "Arial, sans-serif",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%", // Width of the bars
      },
    },
    series: [
      {
        name: "Performance %",
        type: "line",
        data: performanceData,
      },
      {
        name: "Nombre de reponses",
        type: "bar",
        data: answersData,
      },
    ],
    xaxis: {
      categories: categories,
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#9a1ddb",
        },
        labels: {
          style: {
            colors: "#9a1ddb",
          },
        },
        title: {
          text: "Performance %",
          style: {
            color: "#9a1ddb",
          },
        },
      },
      {
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#ff5e00",
        },
        labels: {
          style: {
            colors: "#ff5e00",
          },
        },
        title: {
          text: "Nombre de reponses",
          style: {
            color: "#ff5e00",
          },
        },
      },
    ],
    tooltip: {
      shared: true,
    },
  };

  // Sample data for the chart

  const RadialData = [progressPercentage, completionPercentage];

  // ApexCharts configuration
  const radialOptions = {
    chart: {
      type: "radialBar",
      fontFamily: "Arial, sans-serif",
      height: 350,
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: RadialData,
    labels: ["Modules", "Progression"],
  };

  return (
    <>
      {statistics && (
        <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
          <div className="col-xxl-12">
            <div className="card h-xl-100">
              <div className="card-header position-relative py-0 border-bottom-2">
                <ul className="nav nav-stretch nav-pills nav-pills-custom d-flex mt-3">
                  <li className="nav-item p-0 ms-0 me-8">
                    <a
                      className="nav-link btn btn-color-muted active px-0"
                      data-bs-toggle="tab"
                      id="kt_chart_widgets_22_tab_1"
                      href="#kt_chart_widgets_22_tab_content_1"
                    >
                      <span className="nav-text fw-semibold fs-4 mb-3">
                        Progrès d'apprentissage
                      </span>
                      <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                    </a>
                  </li>
                  <li className="nav-item p-0 ms-0">
                    <a
                      className="nav-link btn btn-color-muted px-0"
                      data-bs-toggle="tab"
                      id="kt_chart_widgets_22_tab_2"
                      href="#kt_chart_widgets_22_tab_content_2"
                    >
                      <span className="nav-text fw-semibold fs-4 mb-3">
                        Performance
                      </span>
                      <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body pb-3">
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="kt_chart_widgets_22_tab_content_1"
                  >
                    <div className="d-flex flex-wrap flex-md-nowrap">
                      <div className="me-md-5 w-100">
                        <div className="d-flex border border-gray-300 border-dashed rounded p-6 mb-6">
                          <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                            <div className="symbol symbol-50px me-4">
                              <span className="symbol-label">
                                <i className="ki-duotone ki-element-11 fs-2qx text-primary">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                  <span className="path3"></span>
                                  <span className="path4"></span>
                                </i>
                              </span>
                            </div>
                            <div className="me-2">
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fs-6 fw-bold"
                              >
                                Modules
                              </a>
                              <span className="text-gray-400 fw-bold d-block fs-7">
                                Nombre de modules terminés
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="text-dark fw-bolder fs-2x">
                              {statistics.completed_modules_count}
                            </span>
                            <span className="fw-semibold fs-2 text-gray-600 mx-1 pt-1">
                              /
                            </span>
                            <span className="text-gray-600 fw-semibold fs-2 me-3 pt-2">
                              {statistics.modules_count}
                            </span>
                            <span className="badge badge-lg badge-light-success align-self-center px-2">
                              {completionPercentage} %
                            </span>
                          </div>
                        </div>
                        <div className="d-flex border border-gray-300 border-dashed rounded p-6 mb-6">
                          <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                            <div className="symbol symbol-50px me-4">
                              <span className="symbol-label">
                                <i className="ki-duotone ki-graph-up fs-2qx text-primary">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                  <span className="path3"></span>
                                  <span className="path4"></span>
                                  <span className="path5"></span>
                                  <span className="path6"></span>
                                </i>
                              </span>
                            </div>
                            <div className="me-2">
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fs-6 fw-bold"
                              >
                                Progression
                              </a>
                              <span className="text-gray-400 fw-bold d-block fs-7">
                                Nombre et taux de QCMs terminés
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="text-dark fw-bolder fs-2x">
                              {statistics.answers_count}
                            </span>
                            <span className="fw-semibold fs-2 text-gray-600 mx-1 pt-1">
                              /
                            </span>
                            <span className="text-gray-600 fw-semibold fs-2 me-3 pt-2">
                              {statistics.questions_count}
                            </span>
                            <span className="badge badge-lg badge-light-warning align-self-center px-2">
                              {progressPercentage} %
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between flex-column w-225px w-md-600px mx-auto mx-md-0 pt-3 pb-10">
                        <div className="fs-4 fw-bold text-gray-900 text-center mb-5">
                          Graphe de <br />
                          progression
                        </div>
                        <ReactApexChart
                          options={radialOptions}
                          series={radialOptions.series}
                          type="radialBar"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="kt_chart_widgets_22_tab_content_2"
                  >
                    <div className="d-flex flex-wrap flex-md-nowrap">
                      <div className="me-md-5 w-100">
                        <div className="d-flex border border-gray-300 border-dashed rounded p-6 mb-6">
                          <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                            <div className="symbol symbol-50px me-4">
                              <span className="symbol-label">
                                <i className="ki-duotone ki-check fs-2qx text-success">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                  <span className="path3"></span>
                                  <span className="path4"></span>
                                </i>
                              </span>
                            </div>
                            <div className="me-2">
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fs-6 fw-bold"
                              >
                                Réponses correctes
                              </a>
                              <span className="text-gray-400 fw-bold d-block fs-7">
                                Nombre et taux de bonnes réponses
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="text-dark fw-bolder fs-2x">
                              {statistics.true_count}
                            </span>
                            <span className="fw-semibold fs-2 text-gray-600 mx-1 pt-1">
                              /
                            </span>
                            <span className="text-gray-600 fw-semibold fs-2 me-3 pt-2">
                              {statistics.answers_count}
                            </span>
                            <span className="badge badge-lg badge-light-success align-self-center px-2">
                              {statistics.answers_count !== 0
                                ? `${(
                                    (statistics.true_count /
                                      statistics.answers_count) *
                                    100
                                  ).toFixed(0)}%`
                                : "0%"}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex border border-gray-300 border-dashed rounded p-6 mb-6">
                          <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                            <div className="symbol symbol-50px me-4">
                              <span className="symbol-label">
                                <i className="ki-duotone ki-cross-square fs-2qx text-danger">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                              </span>
                            </div>
                            <div className="me-2">
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fs-6 fw-bold"
                              >
                                Réponses fausses
                              </a>
                              <span className="text-gray-400 fw-bold d-block fs-7">
                                Nombre et taux de mauvaises réponses
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="text-dark fw-bolder fs-2x">
                              {statistics.wrong_count}
                            </span>
                            <span className="fw-semibold fs-2 text-gray-600 mx-1 pt-1">
                              /
                            </span>
                            <span className="text-gray-600 fw-semibold fs-2 me-3 pt-2">
                              {statistics.answers_count}
                            </span>
                            <span className="badge badge-lg badge-light-danger align-self-center px-2">
                              {statistics.answers_count !== 0
                                ? `${(
                                    (statistics.wrong_count /
                                      statistics.answers_count) *
                                    100
                                  ).toFixed(0)}%`
                                : "0%"}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex border border-gray-300 border-dashed rounded p-6 mb-6">
                          <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                            <div className="symbol symbol-50px me-4">
                              <span className="symbol-label">
                                <i className="ki-duotone ki-arrow-up-refraction fs-2qx text-warning">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                              </span>
                            </div>
                            <div className="me-2">
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fs-6 fw-bold"
                              >
                                Réponses partiellement justes
                              </a>
                              <span className="text-gray-400 fw-bold d-block fs-7">
                                Nombre et taux de réponses partiellement justes
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="text-dark fw-bolder fs-2x">
                              {statistics.part_true_count}
                            </span>
                            <span className="fw-semibold fs-2 text-gray-600 mx-1 pt-1">
                              /
                            </span>
                            <span className="text-gray-600 fw-semibold fs-2 me-3 pt-2">
                              {statistics.answers_count}
                            </span>
                            <span className="badge badge-lg badge-light-warning align-self-center px-2">
                              {statistics.answers_count !== 0
                                ? `${(
                                    (statistics.part_true_count /
                                      statistics.answers_count) *
                                    100
                                  ).toFixed(0)}%`
                                : "0%"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between flex-column w-225px w-md-600px mx-auto mx-md-0 pt-3 pb-10">
                        <div className="fs-4 fw-bold text-gray-900 text-center mb-5">
                          Performance totale
                          <br />
                          des QCMs
                        </div>
                        <Chart
                          options={AnswersOptions}
                          series={data}
                          type="donut"
                          width="100%"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-12">
            <div
              id="kt_sliders_widget_3_slider"
              className="card card-flush carousel slide h-xl-100"
              data-bs-ride="carousel"
              data-bs-interval="5000"
            >
              <div className="card-header pt-5 mb-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-dark">
                    Participation aux modules
                  </span>
                  <span className="text-danger mt-1 fw-semibold fs-7">
                    Moy.
                    {progressPercentage}% des QCMs sont terminés
                  </span>
                </h3>
                <div className="card-toolbar">
                  <div className="d-flex justify-content-end">
                    <a
                      href="#kt_sliders_widget_3_slider"
                      className="carousel-control-prev position-relative me-5 active"
                      role="button"
                      data-bs-slide="prev"
                    >
                      <i className="ki-duotone ki-left-square fs-2x text-gray-400">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </a>
                    <a
                      href="#kt_sliders_widget_3_slider"
                      className="carousel-control-next position-relative me-1"
                      role="button"
                      data-bs-slide="next"
                    >
                      <i className="ki-duotone ki-right-square fs-2x text-gray-400">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body p-0 card-scroll">
                <div className="carousel-inner">
                  {Object.keys(statistics.module_statistics).map(
                    (moduleName, index) => (
                      <div
                        key={moduleName}
                        className={`carousel-item ${
                          index === 0 ? "active show" : ""
                        }`}
                      >
                        <span className="fw-bold fs-1 mb-3 px-8 text-info">
                          {moduleName}
                        </span>
                        <div className="d-flex align-items-center w-100 px-8">
                          <span className="fs-2 text-gray-400 fw-bold">
                            Progression
                          </span>
                          <div className="progress h-6px w-100 mx-3 bg-light-info">
                            <div
                              className="progress-bar bg-info"
                              role="progressbar"
                              style={{
                                width: `${statistics.module_statistics[moduleName].Progression}%`,
                              }}
                              aria-valuenow={
                                statistics.module_statistics[moduleName]
                                  .Progression
                              }
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <span className="text-gray-400 fw-bold fs-4">
                            {
                              statistics.module_statistics[moduleName]
                                .Progression
                            }
                            %
                          </span>
                        </div>
                        <div className="d-flex align-items-center w-100 px-8">
                          <div
                            className="table-responsive"
                            style={{ height: "330px" }}
                          >
                            <table className="table table-striped table-row-dashed table-row-gray-600 align-middle gs-10 gy-4">
                              <thead>
                                <tr className="fw-bold text-gray-800">
                                  <th className="min-w-200px">Cours</th>
                                  <th className="min-w-auto">
                                    Réponses correctes
                                  </th>
                                  <th className="min-w-auto">
                                    Réponses fausses
                                  </th>
                                  <th className="min-w-auto">
                                    Réponses partiellement correctes
                                  </th>
                                  <th className="min-w-auto">Performance</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(
                                  statistics.module_statistics[moduleName]
                                ).map((courseName) =>
                                  courseName !== "Progression" ? (
                                    <tr key={courseName}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className="d-flex justify-content-start flex-column">
                                            <span className="text-dark fw-semibold d-block fs-3 mw-200px">
                                              {courseName}
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="text-center">
                                        <span className="text-primary fw-bolder fs-2x text-center">
                                          {
                                            statistics.module_statistics[
                                              moduleName
                                            ][courseName]["Réponses correctes"]
                                          }
                                        </span>
                                      </td>
                                      <td className="text-center">
                                        <span className="text-danger fw-bolder fs-2x text-center">
                                          {
                                            statistics.module_statistics[
                                              moduleName
                                            ][courseName]["Réponses fausses"]
                                          }
                                        </span>
                                      </td>
                                      <td className="text-center">
                                        <span className="text-warning fw-bolder fs-2x text-center">
                                          {
                                            statistics.module_statistics[
                                              moduleName
                                            ][courseName][
                                              "Réponses partiellement juste"
                                            ]
                                          }
                                        </span>
                                      </td>
                                      <td>
                                        <span className="badge badge-lg badge-info align-self-center px-2">
                                          {
                                            statistics.module_statistics[
                                              moduleName
                                            ][courseName]["Performance"]
                                          }{" "}
                                          %
                                        </span>
                                      </td>
                                    </tr>
                                  ) : null
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-12">
            <div
              id="kt_sliders_widget_3_slider"
              className="card card-flush carousel slide h-xl-100"
              data-bs-ride="carousel"
              data-bs-interval="5000"
            >
              <div className="card-header pt-5 mb-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-dark">
                    Mes reponses
                  </span>
                  <span className="text-gray-400 mt-1 fw-semibold fs-7">
                    Nombre et performance des reponses par mois
                  </span>
                </h3>
              </div>
              <div className="card-body p-0 card-scroll">
                <ReactApexChart
                  options={options}
                  series={options.series}
                  type="line"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Statistics;