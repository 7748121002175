import React, { useCallback, useEffect, useState } from "react";
import { useHelpers, useRemirrorContext } from "@remirror/react";
import { WysiwygEditor } from "@remirror/react-editors/wysiwyg";
import { useSelector, useDispatch } from "react-redux";
import { postNote } from "features/medecine";
import toast, { Toaster } from "react-hot-toast";
import { useRef } from "react";

const NoteContent = ({ course_id }) => {
  const dispatch = useDispatch();
  const { note } = useSelector((state) => state.medecine);
  const buttonReff = useRef(null);
  const [noteMessage, setNoteMessage] = useState(null);
  const notify = () => toast.success(noteMessage);
  
  const [sample_doc, setSamble_doc] = useState(() => {
    const content = note?.content
      ? JSON.parse(note.content).map((obj) => ({ ...obj }))
      : [];
    return {
      type: "doc",
      content,
    };
  });
  useEffect(() => {
const contentArray = note?.content
      ? JSON.parse(note.content).map((obj) => ({ ...obj }))
      : [];
    const object = {
      type: "doc",
      content: contentArray,
    };
    setSamble_doc(object);
  }, [note]);

  const handleButtonClick = () => {
    const toastButton = buttonReff.current;
    if (toastButton) {
      if (note) {
        setNoteMessage("Note a été ajoutée  avec succès");
      }
      toastButton.click();
    }
  };

  function SaveButton() {
    const { getJSON } = useHelpers();
    const { setContent } = useRemirrorContext();
    const handleClick = useCallback(() => {
      const jsonContent = getJSON();
      if (jsonContent && jsonContent.content) {
        const contentString = JSON.stringify(jsonContent.content);
        dispatch(postNote({ course_id, content: contentString }));
        const object = {
          type: "doc",
          content: JSON.parse(contentString).map((obj) => ({
            ...obj,
          })),
        };
        setContent(object);
        handleButtonClick();
      }
    }, [course_id, getJSON, dispatch, setContent]);

    return (
      <button
        id="save-button"
        className="btn btn-primary btn-sm me-5"
        onMouseDown={(event) => event.preventDefault()}
        onClick={handleClick}
        style={{ display: "none" }}
      >
        Save
      </button>
    );
  }

  const handleEnregistrerClick = () => {
    document.getElementById("save-button").click();
  };

  return (
    <>
      <button
        hidden={true}
        type="button"
        className="btn btn-primary"
        onClick={notify}
        ref={buttonReff}
      >
        Toggle Toast
      </button>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      <div className="card bg-light shadow-sm border-gray-700 border rounded-0">
        <div className="card-header">
          <h3 className="card-title fs-2">Mes Notes</h3>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-sm btn-secondary text-white me-2"
              onClick={handleEnregistrerClick}
            >
              Enregistrer
            </button>
          </div>
        </div>
        <div className="card-body card-scroll h-350px p-1">
          <WysiwygEditor
            placeholder="Start typing..."
            initialContent={sample_doc}
          >
            <SaveButton />
          </WysiwygEditor>
        </div>
      </div>
    </>
  );
};

export default NoteContent;
