import { Helmet } from "react-helmet";

const General = ({ title, content, children }) => (
  <>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={content} />
      <meta charset="utf-8" />
      <meta
        name="keywords"
        content="plateforme d'apprentissage en ligne, e-learning médical, formation médicale en ligne, cours de médecine, examens médicaux, préparation aux examens, résidanat, externat, biologie médicale, sciences de la santé, spécialités médicales, formation continue, modules médicaux, éducation en santé, apprentissage à distance, cours en ligne pour médecins, études de médecine, formation des professionnels de santé, faculté de médecine, formation médicale spécialisée, formation santé en ligne, préparation concours médical, examens en ligne médecine, Faculté de Médecine Oran, Faculté de Médecine Constantine, Faculté de Médecine Sétif, Faculté de Médecine Alger"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta
        property="og:title"
        content="Plateforme d'E-learning Médical en Algérie - Préparation au Résidanat et Externat"
      />
      <meta property="og:url" content="https://app.elmajor.com/" />
      <meta property="og:site_name" content="Elmajor Plate-forme" />
      <link rel="canonical" href="https://app.elmajor.com/" />
      <meta property="og:locale" content="fr_DZ" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="https://app.elmajor.com/" />
      <meta property="og:locale" content="fr_DZ" />
      <meta property="og:type" content="website" />
      <link rel="shortcut icon" href="/assets/media/logos/favicon.ico" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700"
      />
      <link
        href="/assets/plugins/global/plugins.bundle.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="/assets/css/style.bundle.css"
        rel="stylesheet"
        type="text/css"
      />

      <script src="/assets/plugins/global/plugins.bundle.js" />
      <script src="/assets/js/scripts.bundle.js" />
    </Helmet>
    <div
      id="kt_body"
      className="auth-bg bgi-size-cover bgi-position-center bgi-no-repeat"
    >
      <div className="d-flex flex-column flex-root">
        <style>
          {`
                        body {
                        background-image: url(${process.env.PUBLIC_URL}/assets/media/auth/bg8.jpg);
                        }

                        [data-bs-theme="dark"] body {
                        background-image: url(${process.env.PUBLIC_URL}/assets/media/auth/bg8-dark.jpg);
                        }
                    `}
        </style>
        <div className="d-flex flex-column flex-center flex-column-fluid">
          <div className="d-flex flex-column flex-center text-center p-10">
            <div className="card card-flush w-md-650px py-5">{children}</div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default General;
