import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { navigateToQuiz } from "./sidebar/utils/utils";
import toast, { Toaster } from "react-hot-toast";
import {
  getCourses,
  getModules,
  getNote,
  getPlaylistQuestions,
  setSelectedCourseId,
} from "features/medecine";
import { setPageVisible } from "features/homeSlice";
import Select, { components } from 'react-select';

const CustomOption = (props) => {
  const { data } = props;

  return (
      <components.Option {...props}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={data.image} alt={data.label} style={{ width: 20, height: 20, marginRight: 10 }} />
              <span>{data.label}</span>
          </div>
      </components.Option>
  );
};
const Sources = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const buttonRef = useRef(null);
  const isNoteVisible = useSelector((state) => state.home.note);
  const isPlaylistVisible = useSelector((state) => state.home.playlists);
  
  const { loading, playlistModules, questions, modules, courses } = useSelector(
    (state) => state.medecine
  );
  const { subscription } = useSelector((state) => state.subscription);
  const { isDarkMode } = useSelector((state) => state.home);
  const [currentModules, setCurrentModules] = useState([]);
  const [currentPlaylistModules, setCurrentPlaylistModules] = useState([]);
  const [currentCourses, setCurrentCourses] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [questionsReady, setQuestionsReady] = useState(false);
  const [message, setMessage] = useState(
    "Veuillez fournir le cours et le module !"
  );
  const notify = () => toast.error(message);

  const specialityOptions = [
    { value: "medecine", label: "Medecine" },
    { value: "chirurgie", label: "Chirurgie" },
    { value: "biologie", label: "Biologie" },
  ];

  useEffect(() => {
    const modulesPlaylistOptions = playlistModules
      ? playlistModules.map((module) => ({
          value: module.id,
          label: module.title,
          image: `assets/media/modules/${module.title}.svg`,
        }))
      : [];

    setCurrentPlaylistModules(modulesPlaylistOptions);
  }, [playlistModules]);

  useEffect(() => {
    const modulesNoteOptions = modules
      ? modules.map((module) => ({
          value: module.id,
          label: module.title,
          image: `assets/media/modules/${module.title}.svg`,
        }))
      : [];
    const coursesOptions = courses
      ? courses.map((course) => ({
          value: course.id,
          label: course.title,
        }))
      : [];
    setCurrentModules(modulesNoteOptions);
    setCurrentCourses(coursesOptions);
  }, [modules, courses]);

  const handleNoteSpecialityChange = (speciality) => {
    dispatch(getModules(speciality.value));
  };

  const handleModuleNoteChange = (selected) => {
    setSelectedModule(selected);
    dispatch(getCourses(selected.value));
  };

  const handleModulePlaylistChange = (selected) => {
    setSelectedModule(selected);
    // Find the selected module
    const module = playlistModules.find((mod) => mod.id === selected.value);
    if (module) {
      // Map the courses to the desired options format
      const coursesOptions = module.courses.map((course) => ({
        value: course.id,
        label: course.title,
      }));
      setCurrentCourses(coursesOptions);
    }
  };

  const handleCourseChange = (selected) => {
    setSelectedCourse(selected);
  };
  const onSubmitPlaylist = (e) => {
    e.preventDefault();
    if (!selectedCourse || !selectedModule === 0) {
      const toastButton = buttonRef.current;
      if (toastButton) {
        setMessage("Veuillez fournir le cours et le module");
        toastButton.click();
      }
      return;
    }
    setCurrentModules([]);
    setCurrentCourses([]);
    const course_id = selectedCourse.value;
    dispatch(setSelectedCourseId(course_id));
    dispatch(getPlaylistQuestions({ course_id, page:1 }));
    setQuestionsReady(true);
  };

  const onSubmitNote = (e) => {
    e.preventDefault();
    if (!selectedCourse || !selectedModule === 0) {
      const toastButton = buttonRef.current;
      if (toastButton) {
        setMessage("Veuillez fournir le cours et le module");
        toastButton.click();
      }
      return;
    }
    const course_id = selectedCourse.value;
    dispatch(setSelectedCourseId(course_id));
    dispatch(getNote(course_id));
    dispatch(setPageVisible({ page: "sources", visible: true }));

    if (window.innerWidth <= 767) {
      document.getElementById("kt_aside").classList.remove("drawer-on");
    }
  };

  if (questionsReady && questions && !questions.error && !questions.message) {
    navigateToQuiz(navigate, 0, selectedCourse.value, []);
    setQuestionsReady(false);
    return null;
  }

  return (
    <>
      <button
        hidden={true}
        type="button"
        className="btn btn-primary"
        onClick={notify}
        ref={buttonRef}
      >
        Toggle Toast
      </button>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      <div className="col-xxl-12">
        <div className="card card-bordered">
          <div className="card-header position-relative min-h-60px p-0 border-bottom-2">
            <ul className="nav nav-pills nav-pills-custom d-flex position-relative w-100">
              <li className="nav-item mx-0 p-0 w-50">
                <a
                  className={`nav-link btn btn-color-muted border-0 h-100 px-0 ${
                    isPlaylistVisible ? "active" : ""
                  }`}
                  data-bs-toggle="pill"
                  href="#tab_playlist"
                >
                  <i className="ki-duotone ki-element-8 text-secondary fs-2x me-3">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                  </i>
                  <span className="nav-text fw-bold fs-6 mb-3">
                    Consultez Vos Playlists
                  </span>
                  <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                </a>
              </li>
              <li className="nav-item mx-0 px-0 w-50">
                <a
                  className={`nav-link btn btn-color-muted border-0 h-100 px-0 ${
                    isNoteVisible ? "active" : ""
                  }`}
                  data-bs-toggle="pill"
                  href="#tab_note"
                >
                  <i className="ki-duotone ki-note-2 text-secondary fs-2x me-3">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                  </i>
                  <span className="nav-text fw-bold fs-6 mb-3">
                    Consultez et Créez Vos Propres Notes
                  </span>
                  <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                </a>
              </li>
            </ul>
          </div>
          <div className="card-body">
            <div className="tab-content">
              <div
                className={`tab-pane fade ${
                  isPlaylistVisible ? "active show" : ""
                }`}
                id="tab_playlist"
              >
                {playlistModules?.length === 0 && (
                  <>
                    <p className="text-gray-700 fs-4 fw-semibold mb-10">
                      Aucune playlist n'est disponible.
                      <br />
                      Vous pouvez ajouter des questions à la playlist pendant
                      que vous révisez.
                    </p>
                    <div className="text-center px-4">
                      <img
                        src="assets/media/illustrations/sigma-1/18.png"
                        alt=""
                        className="mw-100 mh-200px"
                      />
                    </div>
                  </>
                )}
                {playlistModules?.length !== 0 && (
                  <form onSubmit={onSubmitPlaylist}>
                    <div className="d-flex flex-column mb-5 fv-row">
                      <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                        <span className="required">Module</span>
                      </label>
                      <Select
                        name="module"
                        className="react-select-styled react-select-solid react-select-lg"
                        placeholder="Selectionnez un module..."
                        options={currentPlaylistModules}
                        onChange={handleModulePlaylistChange}
                        components={{ Option: CustomOption }}
                        isLoading={loading}
                        isSearchable={false}
                        value={selectedModule ? selectedModule.title : ""}
                        theme={
                          isDarkMode
                            ? (theme) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#333",
                                  primary: "#555",
                                  neutral0: "#111329",
                                  neutral20: "#555",
                                  neutral80: "#fff",
                                },
                              })
                            : undefined
                        }
                      />
                    </div>
                    <div className="d-flex flex-column mb-5 fv-row">
                      <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                        <span className="required">Cours</span>
                      </label>
                      <Select
                        name="course"
                        placeholder="Selectionnez un cours..."
                        className="react-select-styled react-select-solid react-select-lg"
                        options={currentCourses}
                        onChange={handleCourseChange}
                        isLoading={loading}
                        isSearchable={false}
                        value={selectedCourse ? selectedCourse.title : ""}
                        noOptionsMessage={() =>
                          loading
                            ? "S'il vous plaît, attendez..."
                            : "Aucun cours disponible"
                        }
                        theme={
                          isDarkMode
                            ? (theme) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#333",
                                  primary: "#555",
                                  neutral0: "#111329",
                                  neutral20: "#555",
                                  neutral80: "#fff",
                                },
                              })
                            : undefined
                        }
                      />
                    </div>
                    <div className="d-flex align-items-end">
                      <button type="submit" className="btn btn-primary w-100">
                        Commencer
                      </button>
                    </div>
                  </form>
                )}
              </div>

              <div
                className={`tab-pane fade ${isNoteVisible ? "active show" : ""}`}
                id="tab_note"
              >
                <form onSubmit={onSubmitNote}>
                  {subscription &&
                    subscription.is_active &&
                    subscription.activation_code_fields.plan ===
                      "RESIDANAT" && (
                      <div className="d-flex flex-column mb-5 fv-row">
                        <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                          <span className="required">Épreuve</span>
                        </label>
                        <Select
                          options={specialityOptions}
                          placeholder="Selectionnez une specialité..."
                          className="react-select-styled react-select-solid react-select-lg"
                          onChange={handleNoteSpecialityChange}
                          isSearchable={false}
                          theme={
                            isDarkMode
                              ? (theme) => ({
                                  ...theme,
                                  borderRadius: 4,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#333",
                                    primary: "#555",
                                    neutral0: "#111329",
                                    neutral20: "#555",
                                    neutral80: "#fff",
                                  },
                                })
                              : undefined
                          }
                        />
                      </div>
                    )}

                  <div className="d-flex flex-column mb-5 fv-row">
                    <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                      <span className="required">Module</span>
                    </label>
                    <Select
                      name="module"
                      className="react-select-styled react-select-solid react-select-lg"
                      placeholder="Selectionnez un module..."
                      options={currentModules}
                      onChange={handleModuleNoteChange}
                      components={{ Option: CustomOption }}
                      isLoading={loading}
                      isSearchable={false}
                      value={selectedModule ? selectedModule.title : ""}
                      theme={
                        isDarkMode
                          ? (theme) => ({
                              ...theme,
                              borderRadius: 4,
                              colors: {
                                ...theme.colors,
                                primary25: "#333",
                                primary: "#555",
                                neutral0: "#111329",
                                neutral20: "#555",
                                neutral80: "#fff",
                              },
                            })
                          : undefined
                      }
                    />
                  </div>
                  <div className="d-flex flex-column mb-5 fv-row">
                    <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                      <span className="required">Cours</span>
                    </label>
                    <Select
                      name="course"
                      placeholder="Selectionnez un cours..."
                      className="react-select-styled react-select-solid react-select-lg"
                      options={currentCourses}
                      onChange={handleCourseChange}
                      isLoading={loading}
                      isSearchable={false}
                      value={selectedCourse ? selectedCourse.title : ""}
                      noOptionsMessage={() =>
                        loading
                          ? "S'il vous plaît, attendez..."
                          : "Aucun cours disponible"
                      }
                      theme={
                        isDarkMode
                          ? (theme) => ({
                              ...theme,
                              borderRadius: 4,
                              colors: {
                                ...theme.colors,
                                primary25: "#333",
                                primary: "#555",
                                neutral0: "#111329",
                                neutral20: "#555",
                                neutral80: "#fff",
                              },
                            })
                          : undefined
                      }
                    />
                  </div>
                  <div className="d-flex align-items-end">
                    <button type="submit" className="btn btn-primary w-100">
                      Commencer
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sources;
