import General from "components/auth/General";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { verifyEmail } from "features/auth";
import { Navigate } from "react-router-dom";

const Activate = () => {
    const dispatch = useDispatch();
    const [verified, setVerified] = useState(false);
    const { loading, verifyEmailStatus, verifyEmailError } = useSelector(state => state.user);
    const { uid, token } = useParams();

    const verify_account = () => {
        dispatch(verifyEmail({ uid, token }));
        setVerified(true);
    };

    if (verifyEmailStatus && verified) {
        return <Navigate to='/login' />
    }

    return (
        <General title='Elmajor | Activation du compte' content='Register'>
            <div className="card-body py-15 py-lg-20">
                <div className="mb-4">
                    <a href="/" className="">
                        <img alt="Logo" src="/assets/media/logos/demo3.png" className="h-150px" />
                    </a>
                </div>
                <h1 className="fw-bolder text-gray-900 mb-5">Veuillez activer votre compte</h1>
                
                {verifyEmailError && verifyEmailError.error && <div className="fv-plugins-message-container invalid-feedback">
                  <div data-field="confirm-password" data-validator="identical">
                    {verifyEmailError.error}
                  </div>
                </div>}
                {verifyEmailError && verifyEmailError.uid && <div className="fv-plugins-message-container invalid-feedback">
                  <div data-field="confirm-password" data-validator="identical">
                    {verifyEmailError.uid}
                  </div>
                </div>}
                {loading ? (
                    <button disabled className="btn btn-primary">
                        <span className="">S'il vous plaît, attendez...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                ) : (
                    <div className="mb-11">
                        <button className="btn btn-sm btn-primary" onClick={verify_account}>Activer</button>
                    </div>
                )}
                <div className="mb-0">
                    <img src="/assets/media/auth/please-verify-your-email.png" className="mw-100 mh-300px theme-light-show" alt="" />
                    <img src="/assets/media/auth/please-verify-your-email-dark.png" className="mw-100 mh-300px theme-dark-show" alt="" />
                </div>
            </div>
        </General>
    );

};

export default Activate;
