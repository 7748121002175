import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

const SubsInfo = ({
  setSelectedPlan,
  setSelectedYear,
  setSelectedFaculty,
  selectedPlan,
  setPrice,
  selectedFaculty,
  setMonths,
  selectedAccountNumber,
  setSelectedAccountNumber,
}) => {
  const { plans } = useSelector((state) => state.subscription);

  const handlePlanChange = (selectedOption) => {
    setSelectedPlan(selectedOption.plan);
    setSelectedYear(null);
  };

  // Handle faculty change
  const handleFacultyChange = (selectedOption) => {
    setSelectedFaculty(selectedOption);
  };

  const handleAccountNumbersChange = (selectedOption) => {
    setSelectedAccountNumber(selectedOption.value);
  };

  const yearOptions = [
    { value: "4eme", label: "4ème année" },
    { value: "5eme", label: "5ème année" },
    { value: "6eme", label: "6ème année" },
  ];

  const facultyOptions = [{ value: 31, label: "Oran" }];
  const accountNumbersOptions = [
    { value: 1, label: "1 compte" },
    { value: 5, label: "5 comptes" },
  ];
  // Generate plan options based on the selected faculty
  const filteredPlans = plans.filter((plan) => {
    if (selectedFaculty && selectedFaculty.label === "Oran") {
      return plan.title === "RESIDANAT";
    }
    return true;
  });

  const planOptions = plans.map((plan) => ({
    value: plan.id,
    label: plan.title,
    plan,
  }));

  return (
    <div className="d-flex flex-column mb-8 fv-row mt-3">
      <h2 className="mb-3">Demandez un abonement:</h2>
      <div className="d-flex flex-row mb-5">
        <div className="d-flex flex-column align-items-start flex-grow-1 mx-3">
          <label className="d-flex form-label fs-5 fw-semibold">
            Sélectionnez faculté :
          </label>
          <Select
            options={facultyOptions}
            onChange={handleFacultyChange}
            isSearchable={false}
            placeholder="Choisir une faculté"
            className="w-100"
          />
        </div>
        <div className="d-flex flex-column align-items-start flex-grow-1 mx-3">
          <label className="d-flex form-label fs-5 fw-semibold">
            Sélectionnez plan :
          </label>
          <Select
            options={planOptions}
            placeholder="Choisir un plan"
            onChange={handlePlanChange}
            className="w-100"
            isSearchable={false}
            isDisabled={!selectedFaculty}
          />
        </div>
        {selectedPlan && selectedPlan.title === "EXTERNAT" && (
          <div className="d-flex flex-column align-items-start flex-grow-1 mx-3">
            <label className="d-flex form-label fs-5 fw-semibold">
              Sélectionnez année :
            </label>
            <Select
              options={yearOptions}
              placeholder="Choisir une année"
              isSearchable={false}
              onChange={(option) => {
                setSelectedYear(option);
              }}
              className="w-100"
            />
          </div>
        )}{" "}
      </div>
      <div className="d-flex flex-row mb-5">
        <div className="d-flex flex-column align-items-start flex-grow-1 mx-3">
          <label className="d-flex form-label fs-5 fw-semibold">
            Nombre de comptes :
          </label>
          <Select
            options={accountNumbersOptions}
            onChange={handleAccountNumbersChange}
            isSearchable={false}
            placeholder="Selectionner nombre de comptes"
            className="w-50"
          />
        </div>
      </div>

      <div className="d-flex flex-column fv-row align-items-start">
        {selectedAccountNumber === 1 && (
          <>
            {selectedPlan && selectedPlan.title === "EXTERNAT" && (
              <>
                <label className="d-flex form-label fs-5 fw-semibold">
                  Nombre de mois :
                </label>
                <div className="d-flex flex-column mb-5">
                  <div className="d-flex flex-row mb-5">
                    <div
                      className="d-flex flex-column mx-3 flex-grow-1"
                      data-kt-buttons="true"
                    >
                      <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-3 flex-grow-1">
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="plan"
                              value="3 mois"
                              onChange={() => {
                                setMonths(3);
                                setPrice(600);
                              }}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                              3 Mois
                            </h2>
                          </div>
                        </div>
                        <div className="ms-5">
                          <span className="mb-2">DZD</span>
                          <span className="fs-2x fw-bold">600</span>
                        </div>
                      </label>
                    </div>

                    <div
                      className="d-flex flex-column mx-3 flex-grow-1"
                      data-kt-buttons="true"
                    >
                      <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-3 flex-grow-1">
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="plan"
                              value="6 mois"
                              onChange={() => {
                                setMonths(6);
                                setPrice(1100);
                              }}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                              6 Mois
                            </h2>
                          </div>
                        </div>
                        <div className="ms-5">
                          <span className="mb-2">DZD</span>
                          <span className="fs-2x fw-bold">1100</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex flex-row mb-5">
                    <div
                      className="d-flex flex-column mx-3 flex-grow-1"
                      data-kt-buttons="true"
                    >
                      <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-3 flex-grow-1">
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="plan"
                              value="9 mois"
                              onChange={() => {
                                setMonths(9);
                                setPrice(1500);
                              }}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                              9 Mois
                            </h2>
                          </div>
                        </div>
                        <div className="ms-5">
                          <span className="mb-2">DZD</span>
                          <span className="fs-2x fw-bold">1500</span>
                        </div>
                      </label>
                    </div>

                    <div
                      className="d-flex flex-column mx-3 flex-grow-1"
                      data-kt-buttons="true"
                    >
                      <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-3 flex-grow-1">
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="plan"
                              value="12 mois"
                              onChange={() => {
                                setMonths(12);
                                setPrice(1800);
                              }}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                              12 Mois
                            </h2>
                          </div>
                        </div>
                        <div className="ms-5">
                          <span className="mb-2">DZD</span>
                          <span className="fs-2x fw-bold">1800</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}

            {selectedPlan && selectedPlan.title === "RESIDANAT" && (
              <>
                <label className="d-flex form-label fs-5 fw-semibold">
                  Nombre de mois :
                </label>
                <div className="d-flex flex-column mb-5">
                  <div className="d-flex flex-row mb-5">
                    <div
                      className="d-flex flex-column mx-3 flex-grow-1"
                      data-kt-buttons="true"
                    >
                      <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-3 flex-grow-1">
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="plan"
                              value="3 mois"
                              onChange={() => {
                                setMonths(3);
                                setPrice(1500);
                              }}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                              3 Mois
                            </h2>
                          </div>
                        </div>
                        <div className="ms-5">
                          <span className="mb-2">DZD</span>
                          <span className="fs-2x fw-bold">1500</span>
                        </div>
                      </label>
                    </div>

                    <div
                      className="d-flex flex-column mx-3 flex-grow-1"
                      data-kt-buttons="true"
                    >
                      <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-3 flex-grow-1">
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="plan"
                              value="6 mois"
                              onChange={() => {
                                setMonths(6);
                                setPrice(2800);
                              }}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                              6 Mois
                            </h2>
                          </div>
                        </div>
                        <div className="ms-5">
                          <span className="mb-2">DZD</span>
                          <span className="fs-2x fw-bold">2800</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex flex-row mb-5">
                    <div
                      className="d-flex flex-column mx-3 flex-grow-1"
                      data-kt-buttons="true"
                    >
                      <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-3 flex-grow-1">
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="plan"
                              value="9 mois"
                              onChange={() => {
                                setMonths(9);
                                setPrice(3900);
                              }}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                              9 Mois
                            </h2>
                          </div>
                        </div>
                        <div className="ms-5">
                          <span className="mb-2">DZD</span>
                          <span className="fs-2x fw-bold">3900</span>
                        </div>
                      </label>
                    </div>

                    <div
                      className="d-flex flex-column mx-3 flex-grow-1"
                      data-kt-buttons="true"
                    >
                      <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-3 flex-grow-1">
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="plan"
                              value="12 mois"
                              onChange={() => {
                                setMonths(12);
                                setPrice(5000);
                              }}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                              12 Mois
                            </h2>
                          </div>
                        </div>
                        <div className="ms-5">
                          <span className="mb-2">DZD</span>
                          <span className="fs-2x fw-bold">5000</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {selectedPlan && selectedPlan.title === "RESIDANAT" && (
          <>
            {selectedAccountNumber === 5 && (
              <>
                <label className="d-flex form-label fs-5 fw-semibold">
                  Nombre de mois :
                </label>
                <div className="d-flex flex-column mb-5">
                  <div className="d-flex flex-row mb-5">
                    <div
                      className="d-flex flex-column mx-3 flex-grow-1"
                      data-kt-buttons="true"
                    >
                      <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-3 flex-grow-1">
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="plan"
                              value="12 mois"
                              onChange={() => {
                                setMonths(12);
                                setPrice(20000);
                              }}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                              12 Mois
                            </h2>
                          </div>
                        </div>
                        <div className="ms-5">
                          <span className="mb-2">DZD</span>
                          <span className="fs-2x fw-bold">20000</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {selectedPlan && selectedPlan.title === "EXTERNAT" && (
          <>
            {selectedAccountNumber === 5 && (
              <>
                <label className="d-flex form-label fs-5 fw-semibold">
                  Nombre de mois :
                </label>
                <div className="d-flex flex-column mb-5">
                  <div className="d-flex flex-row mb-5">
                    <div
                      className="d-flex flex-column mx-3 flex-grow-1"
                      data-kt-buttons="true"
                    >
                      <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-3 flex-grow-1">
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="plan"
                              value="06 mois"
                              onChange={() => {
                                setMonths(9);
                                setPrice(6000);
                              }}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                              09 Mois
                            </h2>
                          </div>
                        </div>
                        <div className="ms-5">
                          <span className="mb-2">DZD</span>
                          <span className="fs-2x fw-bold">6000</span>
                        </div>
                      </label>
                    </div>
                    <div
                      className="d-flex flex-column mx-3 flex-grow-1"
                      data-kt-buttons="true"
                    >
                      <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-3 flex-grow-1">
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="plan"
                              value="12 mois"
                              onChange={() => {
                                setMonths(12);
                                setPrice(7200);
                              }}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                              12 Mois
                            </h2>
                          </div>
                        </div>
                        <div className="ms-5">
                          <span className="mb-2">DZD</span>
                          <span className="fs-2x fw-bold">7200</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SubsInfo;
