import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const Layout = ({ title, children }) => {
  const isDarkMode = useSelector((state) => state.home.isDarkMode);

  useEffect(() => {
    const defaultThemeMode = "light";
    let themeMode;

    if (document.documentElement) {
      // Check if the data-bs-theme-mode attribute is present
      if (document.documentElement.hasAttribute("data-bs-theme-mode")) {
        themeMode = document.documentElement.getAttribute("data-bs-theme-mode");
      } else {
        // Use isDarkMode state instead of localStorage
        themeMode = isDarkMode ? "dark" : defaultThemeMode;
      }

      // Set the theme attribute on the document element
      document.documentElement.setAttribute("data-bs-theme", themeMode);
    }
  }, [isDarkMode]);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta charset="utf-8" />
        <meta
          name="keywords"
          content="plateforme d'apprentissage en ligne, e-learning médical, formation médicale en ligne, cours de médecine, examens médicaux, préparation aux examens, résidanat, externat, biologie médicale, sciences de la santé, spécialités médicales, formation continue, modules médicaux, éducation en santé, apprentissage à distance, cours en ligne pour médecins, études de médecine, formation des professionnels de santé, faculté de médecine, formation médicale spécialisée, formation santé en ligne, préparation concours médical, examens en ligne médecine, Faculté de Médecine Oran, Faculté de Médecine Constantine, Faculté de Médecine Sétif, Faculté de Médecine Alger"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          property="og:title"
          content="Plateforme d'E-learning Médical en Algérie - Préparation au Résidanat et Externat"
        />
        <meta property="og:url" content="https://app.elmajor.com/" />
        <meta property="og:site_name" content="Elmajor Plate-forme" />
        <link rel="canonical" href="https://app.elmajor.com/" />
        <meta property="og:locale" content="fr_DZ" />
        <meta property="og:type" content="website" />
        <link rel="shortcut icon" href="/assets/media/logos/favicon.ico" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700"
        />
        <link
          href="/assets/plugins/global/plugins.bundle.css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="/assets/css/style.bundle.css"
          rel="stylesheet"
          type="text/css"
        />
        <body
          id="kt_body"
          className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled"
        />
        <script src="/assets/plugins/global/plugins.bundle.js"></script>
        <script src="/assets/js/scripts.bundle.js"></script>

        <script src="https://cdn.amcharts.com/lib/5/themes/Animated.js"></script>
        <script src="assets/js/custom/pages/social/feeds.js"></script>
        <script src="/assets/js/app.js"></script>
      </Helmet>

      {children}
    </>
  );
};

export default Layout;
