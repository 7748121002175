import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { default as SubsInfo } from "./SubsInfo";
import {
  getPlans,
  getActiveSubscription,
  verifyCoupon,
  setDiscountValue,
  addSubsRequest,
} from "features/subscription";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const UpgradePlanModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState("");
  const [months, setMonths] = useState(null);
  const [selectedFaculty, setSelectedFaculty] = useState(null);
  const [price, setPrice] = useState(0);
  const [selectedYear, setSelectedYear] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [subActivated, setSubActivated] = useState(false);
  const [error, setError] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [selectedAccountNumber, setSelectedAccountNumber] = useState(1);

  const { loading, discount_value, discount_error } = useSelector(
    (state) => state.subscription
  );
  useEffect(() => {
    if (selectedAccountNumber === 5) {
      dispatch(setDiscountValue(20));
    } else if (selectedAccountNumber === 10) {
      dispatch(setDiscountValue(30));
    } else {
      dispatch(setDiscountValue(0));
    }
  }, [selectedAccountNumber, dispatch]);

  // Handler for verifying the coupon
  const handleVerifyCoupon = () => {
    if (months !== 12) {
      return showSwalDiscountError(
        "La remise n'est applicable que pour un plan de 12 mois."
      );
    }
    const plan_id = selectedPlan.id;
    dispatch(verifyCoupon({ coupon_code: couponCode, plan: plan_id }));
  };

  const showSwalDiscountError = (errorMessage) => {
    Swal.fire({
      text: errorMessage,
      icon: "error",
      buttonsStyling: true,
      confirmButtonColor: "#dc3545",
    });
  };

  useEffect(() => {
    if (discount_error) {
      showSwalDiscountError(discount_error.error);
    }
  }, [discount_error]);

  const handleNext = () => {
    if (currentStep === 1 && !canProceed()) {
      setError(true);
    } else if (currentStep < stepContent.length - 1) {
      setCurrentStep(currentStep + 1);
      setError(false);
    }
  };

  const handlePrevious = () => {
    setSelectedPlan();
    setSelectedFaculty();
    setPrice();
    setMonths();
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  const canProceed = () => {
    if (selectedPlan && selectedPlan.title === "EXTERNAT") {
      return selectedPlan && selectedFaculty && selectedYear && price && months;
    }
    return selectedPlan && selectedFaculty && price && months;
  };
  if (selectedAccountNumber) {
  }
  const discountedPrice =
    selectedAccountNumber === 1
      ? discount_value
        ? price - (price * discount_value) / 100
        : price
      : price;

  const discountAmount =
    selectedAccountNumber === 1
      ? discount_value
        ? (price * discount_value) / 100
        : 0
      : price - price * (1 - 20 / 100);

  useEffect(() => {
    dispatch(getPlans());
    dispatch(getActiveSubscription());
  }, [dispatch]);

  const showSwalError = () => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      text: "Veuillez sélectionner tout les champs",
      icon: "error",
      buttonsStyling: true,
      confirmButtonColor: "#dc3545",
    });
  };
  const showSwalServerError = (error) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      text: error,
      icon: "error",
      buttonsStyling: true,
      confirmButtonColor: "#dc3545",
    });
  };
  const showSwalSuccess = () => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      text: "Votre demande a bien été enregistrée. Nous vous invitons à consulter votre boîte e-mail pour les instructions suivantes.",
      icon: "success",
      buttonsStyling: true,
      confirmButtonColor: "#dc3545",
    });
  };

  const planPrices = [
    {
      title: "RESIDANAT",
      three_months: 1500,
      six_months: 2800,
      nine_months: 3900,
      twelve_months: 5000,
    },
    {
      title: "EXTERNAT",
      three_months: 600,
      six_months: 1100,
      nine_months: 1500,
      twelve_months: 1800,
    },
  ];
  const handleSubmit = () => {
    if (!selectedPlan || !selectedFaculty || !price) {
      showSwalError();
      return;
    }

    const data = {
      plan: selectedPlan.id,
      faculty: selectedFaculty.value,
      year: selectedYear ? selectedYear.label : "Non spécifié",
      months,
      price,
    };

    dispatch(addSubsRequest(data))
      .unwrap()
      .then((result) => {
        showSwalSuccess();
        handleNext();
      })
      .catch((error) => {
        showSwalServerError(error.error);
      });
  };
  if (subActivated) {
    setSubActivated(false);
    localStorage.setItem("subActivated", "true");
    setTimeout(() => {
      navigate(0);
    }, 100);
  }
  const currentDate = new Date().toLocaleDateString("fr-FR", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  const stepContent = [
    <div className="current" data-kt-stepper-element="content">
      <div class="w-100">
        <div class="fv-row mb-10">
          <div class="table-responsive">
            <table class="table table-row-dashed table-row-gray-300 gy-7">
              <thead>
                <tr class="fw-bold fs-6 text-gray-800">
                  <th>Plan</th>
                  <th> 3 mois</th>
                  <th>6 mois</th>
                  <th>9 mois</th>
                  <th>12 mois</th>
                </tr>
              </thead>
              <tbody>
                {planPrices.map((plan, index) => (
                  <tr key={index}>
                    <td className="fs-4 fw-bold ">{plan.title}</td>
                    <td>
                      <div className="flex-grow-1">
                        <span className="mb-2">DZD</span>
                        <span className="fs-4 fw-bold">
                          {plan.three_months}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="flex-grow-1">
                        <span className="mb-2">DZD</span>
                        <span className="fs-4 fw-bold">{plan.six_months}</span>
                      </div>
                    </td>
                    <td>
                      <div className="flex-grow-1">
                        <span className="mb-2">DZD</span>
                        <span className="fs-4 fw-bold">{plan.nine_months}</span>
                      </div>
                    </td>
                    <td>
                      <div className="flex-grow-1">
                        <span className="mb-2">DZD</span>
                        <span className="fs-4 fw-bold">
                          {plan.twelve_months}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div className="d-flex align-items-center mb-7">
              <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                QCMs Organisés
              </span>
              <i className="ki-duotone ki-check-circle fs-1 text-success">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
            <div className="d-flex align-items-center mb-7">
              <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                Un Seul Utilisateur
              </span>
              <i className="ki-duotone ki-check-circle fs-1 text-success">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
            <div className="d-flex align-items-center mb-7">
              <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                Mode de Révision
              </span>
              <i className="ki-duotone ki-check-circle fs-1 text-success">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
            <div className="d-flex align-items-center mb-7">
              <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                Enregistrement des sessions de révision
              </span>
              <i className="ki-duotone ki-check-circle fs-1 text-success">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
          </div>
          <div class="col">
            <div className="d-flex align-items-center mb-7">
              <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                Modes d'Examen et d'Examen Blancs
              </span>
              <i className="ki-duotone ki-check-circle fs-1 text-success">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
            <div className="d-flex align-items-center mb-7">
              <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                Notes et Playlists Personnelles
              </span>
              <i className="ki-duotone ki-check-circle fs-1 text-success">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
            <div className="d-flex align-items-center mb-7">
              <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                Des Sources Fiables et Spécifiques
              </span>
              <i className="ki-duotone ki-check-circle fs-1 text-success">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
            <div className="d-flex align-items-center mb-7">
              <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                Statistiques détaillées
              </span>
              <i className="ki-duotone ki-check-circle fs-1 text-success">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
          </div>
        </div>
      </div>
    </div>,
    <div className="current" data-kt-stepper-element="content">
      <div class="w-100">
        <div class="fv-row">
          <SubsInfo
            setSelectedPlan={setSelectedPlan}
            setSelectedYear={setSelectedYear}
            setSelectedFaculty={setSelectedFaculty}
            selectedPlan={selectedPlan}
            setPrice={setPrice}
            selectedFaculty={selectedFaculty}
            setMonths={setMonths}
            selectedAccountNumber={selectedAccountNumber}
            setSelectedAccountNumber={setSelectedAccountNumber}
          />
        </div>
      </div>
    </div>,
    <div className="current" data-kt-stepper-element="content">
      <div class="w-100">
        <div className="col-lg-12">
          <div className="d-print-none border border-dashed border-gray-400 card-rounded h-lg-100 min-w-md-350px p-4 bg-lighten">
            <div className="d-flex flex-stack pb-3">
              <div className="mb-8">
                <span className="badge badge-light-warning">
                  Paiement en attente
                </span>
              </div>
              {selectedAccountNumber === 1 && (
                <div className="input-group w-50">
                  <input
                    type="text"
                    className="form-control border-primary"
                    placeholder="Avez-vous un code coupon ?"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                  <button
                    className="btn btn-primary btn-active-light flex-shrink-0"
                    onClick={handleVerifyCoupon}
                    disabled={loading} // Disable button while loading
                  >
                    {loading ? "Checking..." : "Utiliser"}
                  </button>
                </div>
              )}
            </div>
            <div class="d-flex justify-content-between flex-column flex-md-row">
              <div class="flex-grow-1 pt-8 mb-0">
                <div class="table-responsive border-bottom mb-3">
                  <table class="table">
                    <thead>
                      <tr class="border-bottom fs-7 fw-bold text-muted text-uppercase">
                        <th class="min-w-70px pb-5">Faculté</th>
                        <th class="min-w-100px pb-5 text-end">Plan</th>
                        <th class="min-w-40px pb-5 text-end">Durée</th>
                        <th class="min-w-20px pb-5 text-end">N<sup>0</sup> comptes</th>
                        <th class="min-w-50px pb-5 text-end">Prix</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="fw-bold text-gray-700 fs-6  text-end">
                        <td class="d-flex align-items-center pt-5">
                          <i class="fa fa-genderless text-danger fs-1 me-2"></i>
                          {selectedFaculty
                            ? selectedFaculty.label
                            : "Aucune sélection"}
                        </td>
                        <td className="pt-5 fs-7">
                          {selectedPlan ? (
                            <>
                              {selectedPlan.title}
                              {selectedYear && (
                                <>
                                  <br />
                                  {selectedYear.label}
                                </>
                              )}
                            </>
                          ) : (
                            "Aucun plan sélectionné"
                          )}
                        </td>
                        <td class="pt-5">{months} mois</td>
                        <td class="pt-5">{selectedAccountNumber} comptes</td>
                        <td class="pt-5 fs-5 pe-lg-6 text-dark fw-bolder">
                          <span className="mb-2 fs-7">DZD</span>
                          {price}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="border-end d-none d-md-block mh-450px mx-9"></div>
              <div class="text-end pt-10">
                <div class="fs-4 fw-bold text-secondary mb-1">REMISE</div>
                <div class="fs-1 fw-bolder">
                  <span className="mb-2 fs-5">DZD</span>
                  {discountAmount}
                </div>
                {discount_value && (
                  <span className="mb-2 text-secondary fs-5">
                    ( {discount_value} %)
                  </span>
                )}
                <div class="border-bottom w-100 my-2 my-lg-2"></div>
                <div class="fs-3 fw-bold text-secondary mb-1">
                  MONTANT TOTAL
                </div>
                <div class="fs-xl-2x fs-1x fw-bolder">
                  <span className="mb-2 fs-3">DZD</span>
                  {discountedPrice}
                </div>
                <div class="text-muted fw-semibold">Taxes exclues</div>
                <div class="border-bottom w-100 my-7 my-lg-5"></div>
                <div class="text-gray-500 fs-6 fw-semibold mb-3">DATE</div>
                <div class="fs-6 text-gray-900 fw-semibold">{currentDate}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    <div className="current" data-kt-stepper-element="content">
      <div class="w-100 text-center">
        <h1 class="fw-bold text-dark mb-3">Lancement !</h1>
        <div class="text-muted fw-semibold fs-3">
          Votre demande a bien été enregistrée. Nous vous invitons à consulter
          votre boîte e-mail pour les instructions suivantes.
        </div>
        <div class="text-center px-4 py-15">
          <img
            src="assets/media/illustrations/sigma-1/3-dark.png"
            alt=""
            class="mw-50 mh-150px"
          />
        </div>
      </div>
    </div>,
  ];
  const steps = [
    { title: "Info Générale", description: "Découvrez nos plans" },
    { title: "Abonnement", description: "Choisissez votre plan" },
    { title: "Détails de Paiement", description: "Résumé du paiement" },
    { title: "Terminer", description: "Revue et Soumission" },
  ];

  return (
    <div
      className="modal fade"
      id="kt_modal_upgrade_plan"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content rounded">
          <div class="modal-header">
            <h2>Mettre à niveau votre plan</h2>
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <i class="ki-duotone ki-cross fs-1">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </div>
          </div>
          <div className="modal-body py-lg-10 px-lg-10">
            <div class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid">
              <div class="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
                <div className="stepper-nav ps-lg-10">
                  {steps.map((step, index) => {
                    const stepNumber = index + 1;
                    const isCurrent = stepNumber - 1 === currentStep;
                    const isCompleted = stepNumber < currentStep;

                    return (
                      <div
                        key={index}
                        className={`stepper-item ${
                          isCurrent ? "current" : ""
                        } ${isCompleted ? "mark-completed" : ""}`}
                        data-kt-stepper-element="nav"
                      >
                        <div className="stepper-wrapper">
                          <div className="stepper-icon w-40px h-40px">
                            <i className="ki-duotone ki-check stepper-check fs-2"></i>
                            <span className="stepper-number">{stepNumber}</span>
                          </div>
                          <div className="stepper-label">
                            <h3 className="stepper-title">{step.title}</h3>
                            <div className="stepper-desc">
                              {step.description}
                            </div>
                          </div>
                        </div>
                        {index < steps.length - 1 && (
                          <div className="stepper-line h-40px"></div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div class="flex-row-fluid py-lg-5 px-lg-15">
                {error && (
                  <span className="text-danger">
                    Tous les champs sont requis
                  </span>
                )}
                {stepContent[currentStep]}
                <div className="d-flex flex-stack pt-10">
                  <div className="me-2">
                    <button
                      type="button"
                      className="btn btn-lg btn-light-primary me-3"
                      onClick={handlePrevious}
                      disabled={
                        currentStep === 0 ||
                        currentStep === stepContent.length - 1
                      }
                    >
                      Precedent
                    </button>
                  </div>
                  <div>
                    {currentStep < stepContent.length - 1 ? (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        onClick={currentStep === 2 ? handleSubmit : handleNext}
                      >
                        {currentStep === 2 ? "Envoyer" : "Continue"}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        data-bs-dismiss="modal"
                      >
                        Terminer
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlanModal;
