export const navigateToQuiz = (navigate, module_id, course_id, sources_ids) => {
  const queryParams = {
    sources: sources_ids,
    course_id: course_id,
    module_id: module_id,
  };

  // Store the queryParams in local storage
  localStorage.setItem("queryParams", JSON.stringify(queryParams));

  navigate("/quiz");
};

export const navigateToExam = (navigate) => {
  navigate("/exam");
};

export const activeResidanat = (subscription) => {
  return (
    (subscription?.is_active &&
      subscription?.activation_code_fields?.plan === "RESIDANAT") ||
    false
  );
};

export const getUniqueYearsWithSources = (sources) => {
  const yearsSet = new Set();

  sources
    .filter((source) => source.selected)
    .forEach((source) => {
      // Flatten the nested arrays in 'years_univ'
      const flattenedYears = source.years_univ.flat();

      // Initialize the source map with num_questions and answered_questions

      // Add each year to the set
      flattenedYears.forEach((year) => {
        yearsSet.add(year);
      });
    });

  // Convert the set to an array and sort it numerically
  const uniqueYears = Array.from(yearsSet).sort((a, b) => b - a);

  return { uniqueYears };
};
