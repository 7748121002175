import { reset_password_confirm } from "features/auth";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Auth from "components/auth/Auth";
import { useParams } from "react-router-dom";
import useRecaptcha from "hooks/useRecaptcha";

const ResetPasswordConfirm = () => {
  const dispatch = useDispatch();
  useRecaptcha("user_reset_password_confirm");
  const recaptcha_token = useSelector((state) => state.recaptcha.token);

  const { loading, error, reset_password_state } = useSelector(
    (state) => state.user
  );
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    new_password: "",
    re_new_password: "",
  });

  const { new_password, re_new_password } = formData;

  useEffect(() => {
    setPasswordsMatch(new_password === re_new_password);
  }, [new_password, re_new_password]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const { uid, token } = useParams();

  const onSubmit = (e) => {
    e.preventDefault();
    if (recaptcha_token) {
      if (new_password === re_new_password) {
        dispatch(
          reset_password_confirm({ uid, token, new_password, re_new_password })
        );
      }
    }
  };

  if (reset_password_state) {
    return <Navigate to="/login" />;
  }

  return (
    <Auth title="Elmajor | Confirm Password" content="Confirm Password">
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
        <div className="d-flex flex-center flex-column flex-lg-row-fluid mt-20 pb-30">
          <div className="w-lg-500px p-10">
            <form className="form w-100" onSubmit={onSubmit}>
              <div className="text-center mb-13">
                <h1 className="text-dark fw-bolder mb-3">
                  Ajouter un nouveau mot de passe
                </h1>
                <div className="text-gray-500 fw-semibold fs-6">
                  Avez-vous déjà réinitialisé le mot de passe ?
                  <a href="login/" className="link-primary fw-bold">
                    {" "}
                    Se connecter
                  </a>
                </div>
              </div>
              <div className="fv-row mb-8" data-kt-password-meter="true">
                <div className="mb-1">
                  <div className="position-relative mb-3">
                    <input
                      className="form-control bg-transparent"
                      type="password"
                      placeholder="Mot de passe"
                      name="new_password"
                      autoComplete="off"
                      onChange={onChange}
                      value={new_password}
                      required
                    />
                    <span
                      className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                      data-kt-password-meter-control="visibility"
                    >
                      <i className="ki-duotone ki-eye-slash fs-2"></i>
                      <i className="ki-duotone ki-eye fs-2 d-none"></i>
                    </span>
                    {error && error.new_password && (
                      <div className="fv-plugins-message-container invalid-feedback">
                        <div
                          data-field="confirm-password"
                          data-validator="identical"
                        >
                          {error.new_password}
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="d-flex align-items-center mb-3"
                    data-kt-password-meter-control="highlight"
                  >
                    <div className="flex-grow-1 bg-gray-400 bg-active-info rounded h-5px me-2"></div>
                    <div className="flex-grow-1 bg-gray-400 bg-active-info rounded h-5px me-2"></div>
                    <div className="flex-grow-1 bg-gray-400 bg-active-info rounded h-5px me-2"></div>
                    <div className="flex-grow-1 bg-gray-400 bg-active-info rounded h-5px"></div>
                  </div>
                </div>
                <div className="text-muted">
                  Utilisez 8 caractères ou plus avec un mélange de lettres, de
                  chiffres et de symboles.
                </div>
              </div>
              <div className="fv-row mb-8">
                <input
                  placeholder="Répéter le mot de passe"
                  name="re_new_password"
                  type="password"
                  autocomplete="off"
                  className="form-control bg-transparent"
                  onChange={onChange}
                  value={re_new_password}
                />

                {!passwordsMatch && (
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div
                      data-field="confirm-password"
                      data-validator="identical"
                    >
                      Le mot de passe et sa confirmation ne sont pas les mêmes
                    </div>
                  </div>
                )}
              </div>
              <div className="d-grid mb-10">
                {loading ? (
                  <button disabled className="btn btn-primary">
                    <span className="">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary">
                    <span className="indicator-label">Ajouter</span>
                  </button>
                )}
              </div>
              <div className="text-gray-500 text-center fw-semibold fs-6">
                Pas encore membre?
                <Link to="/register" className="link-primary">
                  {" "}
                  Se connecter
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Auth>
  );
};

export default ResetPasswordConfirm;
