import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import Chart from "react-apexcharts";
import * as questionUtils from "../utils/questionUtils";

function Exam() {
  const { examQuestions } = useSelector((state) => state.medecine);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [question, setQuestion] = useState(
    examQuestions ? examQuestions[currentQuestionIndex] : {}
  );

  // Update the `question` whenever `currentQuestionIndex` changes
  useEffect(() => {
    setQuestion(examQuestions[currentQuestionIndex]);
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (finishedA && !finished) {
      questionUtils.updateExamPropositionLabels(selectedAnswers, question);
    }
  }, [question]);

  const totalQuestions = examQuestions?.length;
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [newSessionStats, setNewSessionStats] = useState({
    fauxCount: 0,
    justeCount: 0,
    partJusteCount: 0,
    partielNegatif: 0,
    partielPositif: 0,
    toutOuRien: 0,
  });
  const [finished, setFinished] = useState(false);
  const [finishedA, setFinishedA] = useState(false);

  const inputTime = examQuestions[0]?.exam?.duration
    ? examQuestions[0].exam.duration
    : "00:30:00";

  // Split the input time string by ":" to get hours, minutes, and seconds
  const [hours, minutes, seconds] = inputTime.split(":").map(Number);

  // Calculate the total seconds
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;

  // Now you can set the total seconds to the state variable
  const [timeRemaining, setTimeRemaining] = useState(totalSeconds);

  const data = [
    newSessionStats.fauxCount,
    newSessionStats.justeCount,
    newSessionStats.partJusteCount,
  ];
  const labels = ["Faux", "justes", "Part/ justes"];

  const options = {
    series: data,
    labels: labels,
    colors: ["#F44336", "#4CAF50", "#FF9800"],
    chart: {
      type: "donut",
    },
    toolbar: {
      show: false,
    },
  };
  const [timer, setTimer] = useState(null); // State to store the timer ID

  useEffect(() => {
    const newTimer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(newTimer);
          calculateScore(); // Time is up, calculate the score
          return 0; // Ensure timeRemaining does not go below 0
        }
        return prevTime - 1;
      });
    }, 1000);

    setTimer(newTimer); // Store the timer ID

    return () => clearInterval(newTimer); // Clean up the timer on unmount
  }, []);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };
  

  const handleCheckboxChange = (questionId, propositionId) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const selectedProps = prevSelectedAnswers[questionId] || [];
      const updatedSelection = selectedProps.includes(propositionId)
        ? selectedProps.filter((id) => id !== propositionId)
        : [...selectedProps, propositionId];

      return {
        ...prevSelectedAnswers,
        [questionId]: updatedSelection,
      };
    });
  };
  const handleNext = () => {
    if (currentQuestionIndex < totalQuestions - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const calculateScore = () => {
    if (timer !== null) {
      clearInterval(timer);
    }
    questionUtils.calculateExamScore(
      selectedAnswers,
      examQuestions,
      setNewSessionStats
    );
    setFinished(true);
  };
  const showAnswers = () => {
    setFinished(false);
    setFinishedA(true);
    setCurrentQuestionIndex(0);
  };
  return (
    <>
      <div className={finished ? "col-xl-12 mb-5" : "col-xl-8 mb-5"}>
        {!finished ? (
          <div className="card card-flush border-gray-700 card-stretch-7">
            <div className="card-header border-gray-700 p-3 pb-0 d-block ribbon ribbon-top">
              <div className="ribbon-label bg-secondary">
                {`Question ${currentQuestionIndex + 1}/${totalQuestions}`}
              </div>
              <div className="card-title fs-2 p-2">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row">
                    <div
                      className="question-text"
                      dangerouslySetInnerHTML={{
                        __html: question?.question_text,
                      }}
                    />
                  </div>
                  <div className="d-flex flex-row gap-2 mt-2">
                    <span
                      className={`badge ${
                        formatTime(timeRemaining) <= "00:10:00" &&
                        formatTime(timeRemaining) > "00:00:00"
                          ? "badge-danger animated-pulse"
                          : "badge-dark"
                      }`}
                    >
                      <i className="ki-duotone ki-timer text-white fs-2x me-4">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                      {formatTime(timeRemaining)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              {question.propositions.map((proposition, index) => (
                <div key={proposition.id}>
                  <input
                    type="checkbox"
                    className="btn-check"
                    name={`answer-${proposition.id}`}
                    value="apps"
                    id={`kt_radio_option_${proposition.id}`}
                    onChange={() =>
                      handleCheckboxChange(question.id, proposition.id)
                    }
                    checked={
                      selectedAnswers[question.id]?.includes(proposition.id) ||
                      false
                    }
                  />

                  <label
                    id={`kt_radio_option1_${proposition.id}`}
                    className={`proposition btn btn-outline btn-outline-dashed ${
                      selectedAnswers[question.id]?.includes(proposition.id)
                        ? "btn-active-light-primary"
                        : ""
                    } p-1 d-flex align-items-center mb-1`}
                    htmlFor={`kt_radio_option_${proposition.id}`}
                  >
                    <span className="svg-icon svg-icon-muted svg-icon-2hx">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 4L18 12L10 20H14L21.3 12.7C21.7 12.3 21.7 11.7 21.3 11.3L14 4H10Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M3 4L11 12L3 20H7L14.3 12.7C14.7 12.3 14.7 11.7 14.3 11.3L7 4H3Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>

                    <span className="d-block fw-semibold text-start">
                      <span className="text-gray-800 fw-semibold fs-6 p-1">
                        {/* {String.fromCharCode(65 + index)}.{" "} */}
                        {proposition.proposition_text}
                      </span>
                    </span>
                  </label>
                </div>
              ))}
            </div>
            <div className="card-footer d-flex flex-end">
              <div className="d-flex flex-row-fluid flex-end">
                {currentQuestionIndex > 0 && (
                  <button
                    type="button"
                    onClick={handlePrevious}
                    className="btn btn-info hover-rotate-start"
                  >
                    <i className="ki-outline ki-left-square fs-2"></i>Previous
                  </button>
                )}
              </div>
              <div className="d-flex flex-row-fluid flex-end">
                {currentQuestionIndex < totalQuestions - 1 ? (
                  <button
                    type="button"
                    onClick={handleNext}
                    className="btn btn-danger hover-rotate-end"
                  >
                    <i className="ki-outline ki-right-square fs-2"></i>Next
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-danger hover-scale"
                    onClick={calculateScore}
                  >
                    <i className="ki-outline ki-right-square fs-2"></i>Terminer
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="card card-xxl-stretch mb-5 mb-xxl-8">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Vous avez terminer cette session
                </span>
                <span className="text-muted mt-1 fw-semibold fs-7">
                  Voici vos statistiques
                </span>
              </h3>
              <div className="card-toolbar">
                <ul className="nav">
                  <li className="nav-item">
                    <a
                      className="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold px-4 me-1 active"
                      data-bs-toggle="tab"
                      href="#kt_table_widget_5_tab_1"
                    >
                      Global
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-body card-scroll  h-450px py-3">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="kt_table_widget_5_tab_1"
                >
                  <div className="d-flex flex-wrap flex-stack py-3 me-3">
                    <div className="d-flex flex-column flex-grow-2">
                      <span className="text-muted mt-1 fw-semibold fs-5">
                        Performance
                      </span>
                      <div className="d-flex flex-wrap py-8">
                        <Chart
                          options={options}
                          series={data}
                          type="donut"
                          width="100%"
                        />
                      </div>
                      <div className="text-center px-4 mt-10">
                        <div className="m-2 hover-scale">
                          <button
                            className="btn btn-primary fw-semibold"
                            onClick={showAnswers}
                          >
                            Voir reponses
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column flex-grow-2">
                      <span className="text-muted mt-1 fw-semibold fs-5">
                        La note
                      </span>

                      <div className="d-flex flex-wrap py-8">
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-chart fs-2x text-danger me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                {(
                                  (newSessionStats.partielNegatif * 20) /
                                  totalQuestions
                                ).toFixed(2)}
                                /20
                              </span>
                              <br />
                            </h3>
                            <div className="fs-2 fw-bold"></div>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">
                            Partiel negatif
                          </div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-chart fs-2x text-success me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                {(
                                  (newSessionStats.partielPositif * 20) /
                                  totalQuestions
                                ).toFixed(2)}
                                /20
                              </span>
                              <br />
                            </h3>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">
                            Partiel positif
                          </div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-chart fs-2x text-warning me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                {(
                                  (newSessionStats.toutOuRien * 20) /
                                  totalQuestions
                                ).toFixed(2)}
                                /20
                              </span>
                              <br />
                            </h3>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">
                            Tout ou rien
                          </div>
                        </div>
                      </div>
                      <span className="text-muted mt-1 fw-semibold fs-5">
                        Nombre de reponses
                      </span>

                      <div className="d-flex flex-wrap py-8">
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-cross-square fs-2x text-danger me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                {newSessionStats.fauxCount}
                              </span>
                              <br />
                            </h3>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">
                            Repondu faux
                          </div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-check fs-2x text-success me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                {newSessionStats.justeCount}
                              </span>
                              <br />
                            </h3>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">
                            Repondu justes
                          </div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-arrow-up-refraction fs-2x text-warning me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                {newSessionStats.partJusteCount}
                              </span>
                              <br />
                            </h3>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">
                            Part/ justes
                          </div>
                        </div>
                      </div>
                      <span className="text-muted mt-1 fw-semibold fs-5">
                        Duree passee dans cette session
                      </span>
                      <div className="d-flex flex-wrap py-8">
                        <div className="border border-gray-300 border-dashed text-center rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center text-end">
                            <i className="ki-duotone ki-timer fs-2x text-primary me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                            </i>
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                {newSessionStats.dureeTotale}
                              </span>
                              <br />
                            </h3>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400 pt-5">
                            Duree totale
                          </div>
                        </div>
                        <div className="border border-gray-300 border-dashed text-center rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center text-end">
                            <i className="ki-duotone ki-time fs-2x text-primary me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                            </i>
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                {newSessionStats.dureeMoyenne}
                              </span>
                              <br />
                            </h3>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">
                            Duree moyenne <br />
                            par question
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {!finished && (
        <div className="col-xl-4 mb-5">
          <div className="card card-xl-stretch bg-info border-0">
            <div className="card-body pt-5 mb-xl-9 position-relative">
              <div className="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-450px px-1">
                <img
                  src="assets/media/svg/illustrations/easy/8.svg"
                  className="mh-400px mb-9"
                  alt=""
                />
              </div>
              <div className="text-center px-4 mt-10">
                {finished ? (
                  <div className="m-2 hover-scale">
                    <button
                      className="btn btn-primary fw-semibold"
                      onClick={showAnswers}
                    >
                      Voir reponses
                    </button>
                  </div>
                ) : (
                  <div className="m-2 hover-scale">
                    <button
                      className="btn btn-outline btn-outline-light fw-semibold"
                      onClick={calculateScore}
                    >
                      Terminer
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Exam;
