// GuidedTour.js
import { useEffect } from "react";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

const GuidedTour = ({ runTour }) => {
  useEffect(() => {
    const hasSeenTour = localStorage.getItem("hasSeenTour");

    if (runTour && !hasSeenTour) {
      const driverObj = driver({
        showProgress: true,
        steps: [
          {
            popover: {
              title: "Bienvenue sur Elmajor",
              description:
                "Explorez toutes les fonctionnalités de notre plateforme dédiée à la préparation aux examens médicaux. Commencez votre parcours sur Elmajor.",
            },
          },
          {
            element: "#kt_aside_nav_tabs",
            popover: {
              title: "Explorer les Onglets",
              description:
                "Naviguez entre les onglets pour accéder à différentes sections. Laissez-nous vous guider à travers cette visite animée.",
              side: "left",
              align: "start",
            },
          },
          {
            element: "#revision",
            popover: {
              title: "Révisions",
              description:
                "Explorez la section Révisions pour consolider vos connaissances. Accédez à des ressources pédagogiques riches pour une préparation efficace.",
              side: "bottom",
              align: "start",
            },
          },
          {
            element: "#examen",
            popover: {
              title: "Examens",
              description:
                "Accédez à vos examens pratiques et évaluez vos compétences. Préparez-vous de manière ciblée pour réussir vos évaluations médicales.",
              side: "left",
              align: "start",
            },
          },
          {
            element: "#note",
            popover: {
              title: "Notes et listes de lecture",
              description:
                "Créez des listes de lecture personnalisées pour organiser vos ressources d'étude et utilisez la fonction de prise de notes pour enregistrer vos observations et réflexions. Optimisez votre temps d'apprentissage avec une planification efficace et gardez une trace de vos idées importantes.",
              side: "top",
              align: "start",
            },
          },
          {
            element: "#subscription",
            popover: {
              title: "Abonnement",
              description:
                "Explorez les options d'abonnement pour déverrouiller des fonctionnalités avancées. Maximisez votre expérience d'apprentissage avec Elmajor.",
              side: "right",
              align: "start",
            },
          },
          {
            element: "#profile",
            popover: {
              title: "Profil",
              description:
                "Personnalisez votre profil pour refléter votre parcours académique. Ajoutez des détails et suivez votre progression sur Elmajor.",
              side: "right",
              align: "start",
            },
          },
          {
            element: "#statistics",
            popover: {
              title: "Statistiques",
              description:
                "Consultez des statistiques détaillées sur vos performances. Identifiez vos points forts et faibles pour une préparation aux examens plus stratégique.",
              side: "right",
              align: "start",
            },
          },
          {
            element: "#disconnect",
            popover: {
              title: "Déconnexion",
              description:
                "Déconnectez-vous de votre session en toute sécurité. Assurez-vous de toujours protéger votre compte pour une expérience d'apprentissage sécurisée.",
              side: "right",
              align: "start",
            },
          },
          {
            element: "#full-screen",
            popover: {
              title: "Mode Plein Écran",
              description:
                "Utilisez le mode plein écran pour une immersion totale dans vos études. Maximisez la visibilité des ressources et concentrez-vous sur votre préparation.",
              side: "right",
              align: "start",
            },
          },
          {
            element: "#mode",
            popover: {
              title: "Mode Sombre",
              description:
                "Explorez le mode sombre pour une expérience d'étude plus confortable, surtout pendant les sessions nocturnes. Protégez vos yeux et optimisez votre concentration.",
              side: "right",
              align: "start",
            },
          },
          {
            popover: {
              title: "Félicitations !",
              description:
                "Vous avez terminé la visite guidée sur Elmajor. Profitez pleinement de notre plateforme pour une préparation aux examens médicaux réussie.",
            },
          },
        ],
      });

      // Start the tour
      driverObj.drive();

      localStorage.setItem("hasSeenTour", "true");
    }
  }, [runTour]);

  return null;
};

export default GuidedTour;
