import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Navbar = () => {
  const { isAuthenticated } = useSelector(state => state.user)
  const authLinks = (
    <>
      <div className="flex-equal text-end ms-5">
        <NavLink className="btn btn-primary btn-shadow" to="/dashboard">
          Dashboard
        </NavLink>
      </div>
    </>
  )

  const guestLinks = (
    <>
      <div className="flex-equal text-end">
          <NavLink className="btn btn-primary me-2" to="/login">
            Login
          </NavLink>
      </div>
    </>
  )

  return (
    <div className="landing-header" data-kt-sticky="true" data-kt-sticky-name="landing-header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">

      <div className="container overflow-x-hidden">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center flex-equal">
            <Link to="/">
              <img alt="Logo" src="assets/media/logos/landing.svg" className="logo-default h-90px h-lg-100px" />
              <img alt="Logo" src="assets/media/logos/landing.svg" className="logo-sticky h-50px h-lg-100px" />
            </Link>
          </div>
          <div className="d-lg-block" id="kt_header_nav_wrapper">
            <div className="d-lg-block p-5 p-lg-0" data-kt-drawer="true" data-kt-drawer-name="landing-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="200px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_landing_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}">
              <div className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-semibold" id="kt_landing_menu">
                <div className="menu-item">
                  <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#home" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Accueil</a>

                </div>
                <div className="menu-item">
                  <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#about" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">À Propos</a>

                </div>
                <div className="menu-item">
                  <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#pricing" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Plans Tarifaires</a>
                </div>
              </div>
            </div>
          </div>
          {isAuthenticated ? authLinks : guestLinks}
        </div>
      </div>
    </div>)
    ;
};

export default Navbar;
