import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getNote, getQuestions, getSessionQuestions } from "features/medecine";

export const showAnsweredAllQuestionsSwal = (
  dispatch,
  selectedSources,
  selectedCourse,
  selectedYears,
  selectedModule
) => {
  const MySwal = withReactContent(Swal);

  MySwal.fire({
    text: "Vous avez répondu à toutes les questions, veuillez choisir une autre source ou relancer la session!",
    icon: "info",
    buttonsStyling: true,
    showCancelButton: true,
    confirmButtonColor: "#dc3545",
    cancelButtonColor: "#6c757d",
    confirmButtonText: "Redémarrer la session",
    cancelButtonText: "Choisissez d'autres sources",
    customClass: {
      confirmButton: "btn btn-danger mx-2",
      cancelButton: "btn btn-success",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      dispatch(
        getQuestions({
          sources: selectedSources,
          course_id: selectedCourse.value,
          module_id: selectedModule.value,
          years: selectedYears,
          restart_session: true,
        })
      );
    }
  });
};

export const showAnsweredSomeQuestionsSwal = (
  dispatch,
  selectedSources,
  selectedCourse,
  selectedModule,
  selectedYears,
  setQuestionsReady
) => {
  const MySwal = withReactContent(Swal);

  MySwal.fire({
    text: "Vous avez répondu à quelques questions, souhaitez-vous redémarrer ou continuer?",
    icon: "info",
    buttonsStyling: true,
    showCancelButton: true,
    confirmButtonText: "Redémarrer",
    cancelButtonText: "Continuer",
    customClass: {
      confirmButton: "btn btn-danger mx-2",
      cancelButton: "btn btn-success",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      dispatch(
        getQuestions({
          sources: selectedSources,
          course_id: selectedCourse.value,
          module_id: selectedModule.value,
          years: selectedYears,
          restart_session: true,
        })
      );
      setQuestionsReady(true);
    } else {
      dispatch(
        getQuestions({
          sources: selectedSources,
          course_id: selectedCourse.value,
          module_id: selectedModule.value,
          years: selectedYears,
          continue_session: true,
        })
      );
      setQuestionsReady(true);
    }
  });
};

export const handleRestartTour = (setRunTour) => {
  setTimeout(() => {
    document.getElementById("kt_aside").classList.add("drawer-on");
    localStorage.removeItem("hasSeenTour");
    setRunTour(true);
  }, 0);
};

export const subscriptionActivated = (setShowConfetti) => {
  const MySwal = withReactContent(Swal);
  setShowConfetti(true);
  MySwal.fire({
    text: "🎉 Félicitations ! Votre abonnement est maintenant activé.",
    icon: "success",
    buttonsStyling: true,
    confirmButtonText: "Continuer 🚀",
    customClass: {
      confirmButton: "btn btn-success",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 3000);
    }
  });
};

export const showResumeSessionSwal = (
  dispatch,
  session,
  setQuestionsReady,
  qCourse_id
) => {
  const MySwal = withReactContent(Swal);

  // Extract relevant data from sessionData
  const sourcesBadges = session?.sources
    ?.map(
      (source) =>
        `<span className="badge badge-warning m-1">${source.title},</span>`
    )
    .join(" ");
  const yearBadges =
    session?.annee_univ
      ?.map((year) => `<span className="badge badge-primary">${year}</span>`)
      .join(" ") || "Non spécifié";
  MySwal.fire({
    title: "Reprendre la session ?",
    html: `
      <div style="text-align: left;">
          <table style="width: 100%; border-collapse: collapse;">
            <tr>
              <th style="width: 20%; text-align: left; padding: 2px; border-bottom: 1px solid #ddd;">Module</th>
              <td style="text-align: right; padding: 2px; border-bottom: 1px solid #ddd;">${session?.module?.title}</td>
          </tr>
          <tr>
              <th style="text-align: left; padding: 2px; border-bottom: 1px solid #ddd;">Cours</th>
              <td style="text-align: right; padding: 2px; border-bottom: 1px solid #ddd;">${session?.course?.title}</td>
          </tr>
          <tr>
              <th style="text-align: left; padding: 2px; border-bottom: 1px solid #ddd;">Sources</th>
              <td style="text-align: right; padding: 2px; border-bottom: 1px solid #ddd;">${sourcesBadges}</td>
          </tr>
          <tr>
              <th style="text-align: left; padding: 2px; border-bottom: 1px solid #ddd;">Années</th>
              <td style="text-align: right; padding: 2px; border-bottom: 1px solid #ddd;">${yearBadges}</td>
          </tr>
        </tbody>
      </table>
    `,
    icon: "question",
    buttonsStyling: true,
    showCancelButton: true,
    confirmButtonText: "Reprendre",
    cancelButtonText: "Annuler",
    customClass: {
      confirmButton: "btn btn-success mx-2",
      cancelButton: "btn btn-danger",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      const session_id = session.id;
      dispatch(
        getSessionQuestions({ session_id: session_id, restart_session: false })
      );
      setQuestionsReady(true);
      dispatch(getNote(qCourse_id));
    }
  });
};
