import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import {
  getModules,
  getCourses,
  getSources,
  setSelectedCourseId,
  setSelectedModuleId,
  setSelectedSourcesIds,
  setSelectedYearsIds,
  getWhiteExamAPI,
  createSession,
} from "features/medecine";

import { resetCourses, getNote, resetSources } from "features/medecine";
import { addSubscription } from "features/subscription";
import {
  showAnsweredAllQuestionsSwal,
  showAnsweredSomeQuestionsSwal,
  subscriptionActivated,
} from "./utils";
import Confetti from "react-confetti";
import SourceYearSelector from "./SourceYearSelector";
import Select, { components } from 'react-select';

const CustomOption = (props) => {
  const { data } = props;

  return (
      <components.Option {...props}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={data.image} alt={data.label} style={{ width: 20, height: 20, marginRight: 10 }} />
              <span>{data.label}</span>
          </div>
      </components.Option>
  );
};

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.medecine);
  const { isDarkMode } = useSelector((state) => state.home);

  const buttonRef = useRef(null);
  const [message, setMessage] = useState(
    "Veuillez fournir les sources, le cours et le module !"
  );
  const notify = () => toast.error(message);

  const [showConfetti, setShowConfetti] = useState(false);
  const [activationCode, setActivationCode] = useState("");
  const [currentModules, setCurrentModules] = useState(null);
  const [currentCourses, setCurrentCourses] = useState(null);
  const [currentSources, setCurrentSources] = useState([]);
  const [subActivated, setSubActivated] = useState(false);
  const [questionsReady, setQuestionsReady] = useState(false);
  const [selectedModule, setSelectedModule] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);

  const { subscription, error: subscriptionError } = useSelector(
    (state) => state.subscription
  );
  const {
    modules,
    courses,
    sources,
    error: medicineError,
  } = useSelector((state) => state.medecine);

  const specialityOptions = [
    { value: "medecine", label: "Medecine" },
    { value: "chirurgie", label: "Chirurgie" },
    { value: "biologie", label: "Biologie" },
  ];

  useEffect(() => {
    const modulesOptions = modules
      ? modules.map((module) => ({
          value: module.id,
          label: module.title,
          image: `assets/media/modules/${module.title}.svg`,
        }))
      : [];

    const coursesOptions = courses
      ? courses.map((course) => ({
          value: course.id,
          label: course.title,
          has_residanat_source: course.has_residanat_source, // Add this line to include the field
        }))
      : [];

    const SourcesOptions = sources?.map((source) => ({
      value: source.id,
      label: `${source.title} (${source.answered_questions}/${source.num_questions})`,
      source: source,
    }));

    setCurrentModules(modulesOptions);
    setCurrentCourses(coursesOptions);
    setCurrentSources(SourcesOptions);
  }, [modules, courses, sources]);

  useEffect(() => {
    if (
      !subscription ||
      subscription?.activation_code_fields?.plan === "EXTERNAT"
    ) {
      dispatch(getModules());
    }
  }, [subscription]);

  const handleSpecialityChange = (speciality) => {
    dispatch(getModules(speciality.value));
    dispatch(resetCourses());
    dispatch(resetSources());
    setSelectedModule(null);
    setSelectedCourse(null);
    setSelectedSources([]);
  };
  const handleModuleChange = (module) => {
    dispatch(getCourses(module.value));
    setSelectedModule(module);
    dispatch(resetSources());
    setSelectedCourse(null);
    setSelectedSources([]);
  };
  const handleCourseChange = (course) => {
    dispatch(getSources(course.value));
    setSelectedCourse(course);
    setSelectedSources([]);
  };

  const handleActivation = async () => {
    try {
      await dispatch(addSubscription({ activation_code: activationCode }));
      setSubActivated(true);
    } catch (error) {
      console.error("Error adding subscription:", error);
      // Handle error if necessary
    }
  };
  useEffect(() => {
    if (localStorage.getItem("subActivated") === "true") {
      localStorage.removeItem("subActivated");
      subscriptionActivated(setShowConfetti);
    }
  }, []);

  if (subActivated) {
    setSubActivated(false);
    localStorage.setItem("subActivated", "true");
    setTimeout(() => {
      navigate(0);
    }, 100);
  }

  const onSubmitRevision = (e) => {
    e.preventDefault();
    if (!selectedCourse || !selectedModule || selectedSources.length === 0) {
      const toastButton = buttonRef.current;
      if (toastButton) {
        setMessage("Veuillez fournir les sources, le cours et le module");
        toastButton.click();
      }
      return;
    }
    setCurrentModules([]);
    setCurrentCourses([]);
    setCurrentSources([]);
    dispatch(setSelectedCourseId(selectedCourse.value));
    dispatch(setSelectedModuleId(selectedModule.value));
    dispatch(setSelectedSourcesIds(selectedSources));
    dispatch(setSelectedYearsIds(selectedYears));
    dispatch(getNote(selectedCourse.value));
    dispatch(
      createSession({
        sources: selectedSources,
        course_id: selectedCourse.value,
        module_id: selectedModule.value,
        years: selectedYears,
      })
    )
      .unwrap()
      .then(() => {
        setQuestionsReady(true);
      });
  };

  const onSubmitExam = (e) => {
    e.preventDefault();
    if (!selectedModule === 0) {
      const toastButton = buttonRef.current;
      if (toastButton) {
        setMessage("Veuillez fournir le module !");
        toastButton.click();
      }
      return;
    }

    dispatch(getWhiteExamAPI({ module_id: selectedModule.value }))
      .unwrap()
      .then(() => {
        setQuestionsReady(true);
      });
  };

  if (questionsReady && !medicineError) {
    navigate("/quiz");
    setQuestionsReady(false);
    return null;
  }

  if (
    !questionsReady &&
    medicineError &&
    medicineError.error === "You have answered all the questions"
  ) {
    showAnsweredAllQuestionsSwal(
      dispatch,
      selectedSources,
      selectedCourse,
      selectedModule,
      selectedYears
    );
  }

  if (
    !questionsReady &&
    medicineError &&
    medicineError.error ===
      "You have answered some questions. Do you want to restart or continue?"
  ) {
    showAnsweredSomeQuestionsSwal(
      dispatch,
      selectedSources,
      selectedCourse,
      selectedModule,
      selectedYears,
      setQuestionsReady
    );
  }

  const handleExamModuleChange = (module) => {
    setSelectedModule(module);
  };

  return (
    <>
      <button
        hidden={true}
        type="button"
        className="btn btn-primary"
        onClick={notify}
        ref={buttonRef}
      >
        Toggle Toast
      </button>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        {showConfetti && <Confetti />}
        {subscription === null && (
          <div className="col-xxl-6">
            <div
              className="card border-0 h-md-100"
              data-bs-theme="light"
              style={{
                background:
                  "linear-gradient(112.14deg, violet 0%, rgba(58, 123, 213, 0.3) 100%)",
              }}
            >
              <div className="card-body">
                <div className="row align-items-center h-100">
                  <div className="col-12 ps-xl-13">
                    <div className="text-white mb-6 pt-6">
                      <span className="fs-4 fw-semibold me-2 d-block lh-1 pb-2">
                        Mettez à niveau votre plan
                      </span>
                    </div>
                    <span className="fw-bold fs-6 mb-8 d-block opacity-75">
                      Obtenez votre code d'activation dès maintenant pour
                      débloquer des modules exclusifs!
                    </span>
                    {subscriptionError && subscriptionError.message && (
                      <span className="fw-bold fs-6 mb-8 d-block opacity-75 text-danger">
                        {subscriptionError.message}
                      </span>
                    )}
                    <div className="d-flex flex-column flex-sm-row d-grid gap-2">
                      <button
                        className="btn btn-info flex-shrink-0 me-lg-2"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_upgrade_plan"
                      >
                        Voir Instructions
                      </button>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Avez-vous un code d'activation ?"
                          value={activationCode}
                          onChange={(e) => setActivationCode(e.target.value)}
                        />
                        <button
                          className="btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger flex-shrink-0"
                          data-clipboard-target="#kt_clipboard_1"
                          onClick={handleActivation}
                        >
                          Activer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="col-xxl-12">
          <div className="card card-bordered">
            <div className="card-header position-relative min-h-60px p-0 border-bottom-2">
              <ul className="nav nav-pills nav-pills-custom d-flex position-relative w-100">
                <li className="nav-item mx-0 p-0 w-50">
                  <a
                    className="nav-link btn btn-color-muted active border-0 h-100 px-0"
                    data-bs-toggle="pill"
                    href="#tab_revision"
                  >
                    <i className="ki-duotone ki-book-open text-secondary fs-2x me-3">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                    </i>
                    <span className="nav-text fw-bold fs-6 mb-0">
                      Créez Votre Propre Session de Révision
                    </span>
                    <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                  </a>
                </li>
                <li className="nav-item mx-0 px-0 w-50">
                  <a
                    className="nav-link btn btn-color-muted border-0 h-100 px-0"
                    data-bs-toggle="pill"
                    href="#tab_exam"
                  >
                    <i className="ki-duotone ki-book text-secondary fs-2x me-3">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                    </i>
                    <span className="nav-text fw-bold fs-6 mb-0">
                      Créez Votre Propre Examen
                    </span>
                    <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content">
                <div className="tab-pane fade active show" id="tab_revision">
                  <form onSubmit={onSubmitRevision}>
                    {subscription &&
                      subscription.is_active &&
                      subscription.activation_code_fields.plan ===
                        "RESIDANAT" && (
                        <div className="d-flex flex-column mb-5 fv-row">
                          <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                            <span className="required">Épreuve</span>
                          </label>
                          <Select
                            options={specialityOptions}
                            placeholder="Selectionnez une specialité..."
                            className="react-select-styled react-select-solid react-select-lg"
                            onChange={handleSpecialityChange}
                            isSearchable={false}
                            theme={
                              isDarkMode
                                ? (theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#333",
                                      primary: "#555",
                                      neutral0: "#111329",
                                      neutral20: "#555",
                                      neutral80: "#fff",
                                    },
                                  })
                                : undefined
                            }
                          />
                        </div>
                      )}

                    <div className="d-flex flex-column mb-5 fv-row">
                      <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                        <span className="required">Module</span>
                      </label>
                      <Select
                        name="module"
                        className="react-select-styled react-select-solid react-select-lg"
                        placeholder="Selectionnez un module..."
                        options={currentModules}
                        onChange={handleModuleChange}
                        components={{ Option: CustomOption }}
                        isLoading={loading}
                        isSearchable={false}
                        value={selectedModule ? selectedModule.title : ""}
                        theme={
                          isDarkMode
                            ? (theme) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#333",
                                  primary: "#555",
                                  neutral0: "#111329",
                                  neutral20: "#555",
                                  neutral80: "#fff",
                                },
                              })
                            : undefined
                        }
                      />
                    </div>
                    <div className="d-flex flex-column mb-5 fv-row">
                      <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                        <span className="required">Cours</span>
                      </label>
                      <Select
                        name="course"
                        placeholder="Selectionnez un cours..."
                        className="react-select-styled react-select-solid react-select-lg"
                        options={currentCourses}
                        onChange={handleCourseChange}
                        isLoading={loading}
                        isSearchable={false}
                        value={selectedCourse ? selectedCourse.title : ""}
                        noOptionsMessage={() =>
                          loading
                            ? "S'il vous plaît, attendez..."
                            : "Aucun cours disponible"
                        }
                        theme={
                          isDarkMode
                            ? (theme) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#333",
                                  primary: "#555",
                                  neutral0: "#111329",
                                  neutral20: "#555",
                                  neutral80: "#fff",
                                },
                              })
                            : undefined
                        }
                        formatOptionLabel={(option) => (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span>{option.label}</span>
                            {option.has_residanat_source && (
                              <span
                                className="residanat-badge"
                                style={{
                                  marginLeft: 8,
                                  backgroundColor: "#ff5e00", // Violet color
                                  color: "#fff",
                                  padding: "1px 4px",
                                  borderRadius: "12px",
                                  fontSize: "9px",
                                }}
                              >
                                Résidanat
                              </span>
                            )}
                          </div>
                        )}
                      />
                    </div>
                    <div className="mb-10">
                      <SourceYearSelector
                        selectedCourse={selectedCourse}
                        currentSources={currentSources}
                        setSelectedSources={setSelectedSources}
                        setSelectedYears={setSelectedYears}
                      />
                    </div>
                    <div className="d-flex align-items-end">
                      <button type="submit" className="btn btn-primary w-100">
                        Commencer
                      </button>
                    </div>
                  </form>
                </div>

                <div className="tab-pane fade" id="tab_exam">
                  <form onSubmit={onSubmitExam}>
                    {subscription &&
                      subscription.is_active &&
                      subscription.activation_code_fields.plan ===
                        "RESIDANAT" && (
                        <div className="d-flex flex-column mb-5 fv-row">
                          <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                            <span className="required">Épreuve</span>
                          </label>
                          <Select
                            options={specialityOptions}
                            placeholder="Selectionnez une specialité..."
                            className="react-select-styled react-select-solid react-select-lg"
                            onChange={handleSpecialityChange}
                            isSearchable={false}
                            theme={
                              isDarkMode
                                ? (theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#333",
                                      primary: "#555",
                                      neutral0: "#111329",
                                      neutral20: "#555",
                                      neutral80: "#fff",
                                    },
                                  })
                                : undefined
                            }
                          />
                        </div>
                      )}

                    <div className="d-flex flex-column mb-5 fv-row">
                      <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                        <span className="required">Module</span>
                      </label>
                      <Select
                        name="module"
                        className="react-select-styled react-select-solid react-select-lg"
                        placeholder="Selectionnez un module..."
                        options={currentModules}
                        onChange={handleExamModuleChange}
                        isLoading={loading}
                        components={{ Option: CustomOption }}
                        isSearchable={false}
                        value={selectedModule ? selectedModule.title : ""}
                        theme={
                          isDarkMode
                            ? (theme) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#333",
                                  primary: "#555",
                                  neutral0: "#111329",
                                  neutral20: "#555",
                                  neutral80: "#fff",
                                },
                              })
                            : undefined
                        }
                      />
                    </div>
                    <div className="d-flex align-items-end">
                      <button type="submit" className="btn btn-primary w-100">
                        Commencer
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
