import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assistant: false,
  profile: false,
  playlists: false,
  sources: false,
  playlists: false,
  dashboard: true,
  statistics: false,
  invoice: false,
  note: false,
  notePage: false,
  exams: false,
  selectedCourse: null,
  selectedExamSession: null,
  selectedModule: null,
  createRoom: false,
  isDarkMode: localStorage.getItem("data-bs-theme") === "dark",
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setPageVisible: (state, action) => {
      const { page, visible } = action.payload;
      if (state[page] !== visible) {
        Object.keys(state).forEach((key) => {
          if (
            key !== "selectedCourse" &&
            key !== "selectedModule" &&
            key !== "isDarkMode"
          ) {
            console.log(key);
            state[key] = key === page ? visible : false;
	   console.log(page);
          }
        });
      }
    },
    setIsDarkMode: (state, action) => {
      state.isDarkMode = action.payload;
      localStorage.setItem("data-bs-theme", action.payload ? "dark" : "light");
    },
    currentSelectedModule: (state, action) => {
      state.selectedModule = action.payload;
    },
    currentSelectedCourse: (state, action) => {
      state.selectedCourse = action.payload;
    },
    setCreateRoom: (state, action) => {
      state.createRoom = action.payload;
    },
    toggleAssistant: (state) => {
      state.assistant = !state.assistant;
    },
    setSelectedExamSession: (state, action) => {
      state.selectedExamSession = action.payload;
    },
  },
});

export const getVisiblePage = (state) => {
  return Object.keys(state.home).find((key) => state.home[key] === true);
};

export const {
  setPageVisible,
  currentSelectedModule,
  currentSelectedCourse,
  setCreateRoom,
  setIsDarkMode,
  toggleAssistant,
  setSelectedExamSession,
} = homeSlice.actions;
export default homeSlice.reducer;
