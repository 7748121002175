import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const getUser = createAsyncThunk(
	'users/me',
	async (_, thunkAPI) => {
		try {
			const res = await fetch('/api/users/me', {
				method: 'GET',
				headers: {
					Accept: 'application/json',
				},
			});

			const data = await res.json();

			if (res.status === 200) {
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {

			return thunkAPI.rejectWithValue(err.response.data);
		}
	});

export const register = createAsyncThunk(
	'users/register',
	async ({ name, email, password, re_password }, thunkAPI) => {
		const body = JSON.stringify({
			name,
			email,
			password,
			re_password,
		});

		try {
			const res = await fetch('/api/users/register', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body,
			});

			const data = await res.json();

			if (res.status === 201) {
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const profileUpdate = createAsyncThunk(
	'users/profile',
	async ({ firstName, lastName, phone, faculty, city, communication, image }, thunkAPI) => {
		const body = JSON.stringify({
			firstName,
			lastName,
			phone,
			faculty,
			city,
			communication,
			image,
		});

		try {
			const res = await fetch('/api/users/profile', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body,
			});

			const data = await res.json();

			if (res.status === 201) {
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const resendActivation = createAsyncThunk(
	'users/resend_activation',
	async (email, thunkAPI) => {
		const body = JSON.stringify({
			email,
		});
		try {
			const res = await fetch('/api/users/resend_activation', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body,
			});

			const data = await res.json();

			if (res.status === 201) {
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const verifyEmail = createAsyncThunk(
	'users/verify',
	async ({ uid, token }, thunkAPI) => {
		const body = JSON.stringify({ uid, token });

		try {
			const res = await fetch('/api/users/activation', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body,
			});

			if (res.status === 204) {
				return null;
			}

			const data = await res.json();
			return thunkAPI.rejectWithValue(data);
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response ? err.response.data : err.message);
		}
	}
);

export const continueWithGoogleThunk = createAsyncThunk(
	'google/continue',
	async (_, thunkAPI) => {
		try {
			const res = await fetch('/api/google/continue', {
				method: 'GET',
			});

			const data = await res.json();
			if (res.status === 200) {
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {

			return thunkAPI.rejectWithValue(err.response.data);
		}
	});

export const login = createAsyncThunk(
	'users/login',
	async ({ email, password, recaptcha_token }, thunkAPI) => {
		const body = JSON.stringify({
			email,
			password,
			recaptcha_token
		});

		try {
			const res = await fetch('/api/users/login', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body,
			});
			const data = await res.json();

			if (res.status === 200) {
				const { dispatch } = thunkAPI;

				dispatch(getUser());

				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const reset_password = createAsyncThunk(
	'users/reset_password',
	async ({ email }, thunkAPI) => {
		const body = JSON.stringify({
			email
		});

		try {
			const res = await fetch('/api/users/reset_password', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body,
			});

			if (res.status === 204) {
				return null;
			}
			const data = await res.json();
			return thunkAPI.rejectWithValue(data);
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const reset_password_confirm = createAsyncThunk(
	'users/reset_password_confirm',
	async ({ uid, token, new_password, re_new_password }, thunkAPI) => {
		const body = JSON.stringify({
			uid,
			token,
			new_password,
			re_new_password,
		});

		try {
			const res = await fetch('/api/users/reset_password_confirm', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body,
			});
			if (res.status === 204) {
				return null;
			}
			const data = await res.json();
			return thunkAPI.rejectWithValue(data);
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const checkAuth = createAsyncThunk(
	'/users/verify',
	async (_, thunkAPI) => {
		try {
			const res = await fetch('/api/users/verify', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
				},
			});

			const data = await res.json();

			if (res.status === 200) {
				const { dispatch } = thunkAPI;

				dispatch(getUser());
				return data;
			} else if (res.status === 400) {
				const { dispatch } = thunkAPI;

				dispatch(refreshToken());

				return data;
			} else if (res.status === 401) {
				const { dispatch } = thunkAPI;

				dispatch(logout());

				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const refreshToken = createAsyncThunk(
	'/users/refresh-token',
	async (_, thunkAPI) => {
		try {
			const res = await fetch('/api/users/refresh-token', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
				},
			});

			const data = await res.json();

			if (res.status === 200) {
				const { dispatch } = thunkAPI;

				dispatch(getUser());

				return data;
			} else if (res.status === 401 || res.status === 400) {
				const { dispatch } = thunkAPI;

				dispatch(logout());

				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const timeSpentInThePlateform = createAsyncThunk(
	'users/time-spent',
	async ({ timeSpent }, thunkAPI) => {
		const body = JSON.stringify({
			timeSpent
		});
		try {
			const res = await fetch('/api/users/time-spent', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body,
			});
			const data = await res.json();

			if (res.status === 200) {

				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const googleAuthenticate = createAsyncThunk(
	'users/google-authenticate',
	async ({ state, code }, thunkAPI) => {

		const body = JSON.stringify({
			state,
			code,
		});

		try {
			const res = await fetch('/api/users/google-authenticate', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body,
			});
			const data = await res.json();

			if (res.status === 200) {
				const { dispatch } = thunkAPI;

				dispatch(getUser());

				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const logout = createAsyncThunk('users/logout', async (_, thunkAPI) => {
	try {
		const res = await fetch('/api/users/logout', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
			},
		});

		const data = await res.json();

		if (res.status === 200) {
			return data;
		} else {
			return thunkAPI.rejectWithValue(data);
		}
	} catch (err) {
		return thunkAPI.rejectWithValue(err.response.data);
	}
});

const initialState = {
	isAuthenticated: false,
	user: null,
	loading: false,
	registered: false,
	isSubscribed: false,
	verifyEmailStatus: false,
	verifyEmailError: null,
	error: null,
	reset_password_state: false
}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		resetRegistered: state => {
			state.registered = false;
			state.reset_password_state = false;
			state.verifyEmailStatus = false;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(register.pending, state => {
				state.loading = true;
			})
			.addCase(register.fulfilled, (state, action) => {
				state.loading = false;
				state.registered = true;
				state.user = action.payload;
				state.verifyEmailStatus = true;
				state.error = null;
			})
			.addCase(register.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(login.pending, state => {
				state.loading = true;
			})
			.addCase(login.fulfilled, state => {
				state.loading = false;
				state.isAuthenticated = true;
			})
			.addCase(login.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(googleAuthenticate.pending, state => {
				state.loading = true;
			})
			.addCase(googleAuthenticate.fulfilled, state => {
				state.loading = false;
				state.isAuthenticated = true;
			})
			.addCase(googleAuthenticate.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(getUser.pending, state => {
				state.loading = true;
			})
			.addCase(getUser.fulfilled, (state, action) => {
				state.loading = false;
				state.user = action.payload;
			})
			.addCase(getUser.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(checkAuth.pending, state => {
				state.loading = true;
			})
			.addCase(checkAuth.fulfilled, state => {
				state.loading = false;
				state.isAuthenticated = true;
			})
			.addCase(checkAuth.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(logout.pending, state => {
				state.loading = true;
			})
			.addCase(logout.fulfilled, state => {
				state.loading = false;
				state.user = null;
				state.isAuthenticated = false;
			})
			.addCase(logout.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(verifyEmail.pending, state => {
				state.loading = true;
			})
			.addCase(verifyEmail.fulfilled, (state, action) => {
				state.loading = false;
				state.verifyEmailStatus = true;
			})
			.addCase(verifyEmail.rejected, (state, action) => {
				state.loading = false;
				state.verifyEmailError = action.payload;
			})
			.addCase(reset_password.pending, state => {
				state.loading = true;
			})
			.addCase(reset_password.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(reset_password.rejected, (state, action) => {
				state.loading = false;
				state.reset_password_state = false;
				state.error = action.payload;
			})
			.addCase(reset_password_confirm.pending, state => {
				state.loading = true;
			})
			.addCase(reset_password_confirm.fulfilled, (state, action) => {
				state.loading = false;
				state.reset_password_state = true;
			})
			.addCase(reset_password_confirm.rejected, (state, action) => {
				state.loading = false;
				state.reset_password_state = false;
				state.error = action.payload;
			})
			.addCase(profileUpdate.pending, state => {
				state.loading = true;
			})
			.addCase(profileUpdate.fulfilled, (state, action) => {
				state.loading = false;
				state.user = action.payload;
			})
			.addCase(profileUpdate.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(continueWithGoogleThunk.pending, state => {
				state.loading = true;
			})
			.addCase(continueWithGoogleThunk.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(continueWithGoogleThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(resendActivation.pending, state => {
				state.loading = true;
			})
			.addCase(resendActivation.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(resendActivation.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
	},
});

export const { resetRegistered } = userSlice.actions
export default userSlice.reducer