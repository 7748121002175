import React from "react";
import { useDispatch } from "react-redux";

import { setPageVisible } from "features/homeSlice";
import { getStatistics } from "features/medecine";
import { logout } from "features/auth";
import ReactGA from "react-ga4";

const AsideFooter = () => {
  const dispatch = useDispatch();

  const handleToggleProfile = () => {
    // Track the toggle profile event
    ReactGA.event({
      category: "User Interface",
      action: "Toggle Profile",
      label: "Profile Visibility Toggled",
      value: 1, // You can use 1 to indicate the toggle action (optional)
    });

    dispatch(setPageVisible({ page: "profile", visible: true }));
  };
  const getStatisticsPage = () => {
    // Track the statistics page access event
    ReactGA.event({
      category: "Navigation",
      action: "Access Statistics Page",
      label: "Statistics Page Accessed",
      value: null, // Optional, can be used for additional context
    });

    dispatch(getStatistics());
    dispatch(setPageVisible({ page: "statistics", visible: true }));
  };

  return (
    <div
      className="aside-footer d-flex flex-column align-items-center flex-column-auto bg-light"
      id="kt_aside_footer"
    >
      <div id="profile" className="d-flex align-items-center mb-2">
        <div
          className="btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          data-bs-dismiss="click"
          title="Profile"
          onClick={handleToggleProfile}
        >
          <i className="ki-duotone ki-badge text-info fs-2 fs-lg-1">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
            <span className="path5"></span>
          </i>
        </div>
      </div>
      <div id="statistics" className="d-flex align-items-center mb-2">
        <div
          className="btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          data-bs-dismiss="click"
          title="Statistiques"
          onClick={() => getStatisticsPage()}
        >
          <i className="ki-duotone ki-chart-pie-3 text-info fs-2 fs-lg-1">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
            <span className="path5"></span>
            <span className="path6"></span>
          </i>
        </div>
      </div>
      <div id="disconnect" className="d-flex align-items-center mb-2">
        <div
          className="btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          data-bs-dismiss="click"
          title="Déconnecter"
          onClick={() => dispatch(logout())}
        >
          <i className="ki-duotone ki-user text-danger fs-2 fs-lg-1">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
            <span className="path5"></span>
            <span className="path6"></span>
          </i>
        </div>
      </div>
    </div>
  );
};

export default AsideFooter;
