import React, { useEffect } from "react";

const Facebook = () => {
  useEffect(() => {
    (function (f, b, e, v, n, t, s) { // Added parentheses
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );

    // Use window.fbq to prevent undefined error
    if (window.fbq) {
      window.fbq("init", "699787568712246");
      window.fbq("track", "PageView");
    }
  }, []);

  return null;
};

export default Facebook;
