import toast from "react-hot-toast";
import evaluationMessages from "./evaluationMessages.json";

export const getSelectedPropositionIds = (questions) => {
  const selectedPropositionIds = [];
  questions.propositions.forEach((proposition) => {
    const checkbox = document.getElementById(
      `kt_radio_option_${proposition.id}`
    );
    if (checkbox.checked) {
      selectedPropositionIds.push(proposition.id);
    }
  });
  return selectedPropositionIds;
};

export const updatePropositionLabels = (updatedPropositions) => {
  updatedPropositions.forEach((proposition, index) => {
    const checkboxLabel = document.querySelector(
      `label[for="kt_radio_option_${proposition.id}"]`
    );

    if (checkboxLabel) {
      if (proposition.isChecked && proposition.is_correct) {
        checkboxLabel.classList.remove("btn-active-light-info");
        checkboxLabel.classList.add("btn-active-light-primary");
        checkboxLabel.innerHTML = `
                        <span className="svg-icon svg-icon-muted svg-icon-2hx">
                          <i className="ki-duotone ki-check-circle fs-2x">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                        <span className="d-block fw-semibold text-start">
                            <span className="text-gray-900 fw-semibold fs-6 p-1">
                                ${String.fromCharCode(65 + index)}. ${
          proposition.proposition_text
        }
                            </span>
                        </span>
                    `;
      } else if (!proposition.isChecked && proposition.is_correct) {
        checkboxLabel.classList.add("btn-light-primary");
        checkboxLabel.innerHTML = `
                        <span className="svg-icon svg-icon-muted svg-icon-2hx">
                            <i className="ki-duotone ki-check-circle fs-2x">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                        </span>
                        <span className="d-block fw-semibold text-start">
                            <span className="text-gray-900 fw-semibold fs-6 p-1">
                                ${String.fromCharCode(65 + index)}. ${
          proposition.proposition_text
        }
                            </span>
                        </span>
                    `;
      } else if (proposition.isChecked && !proposition.is_correct) {
        checkboxLabel.classList.remove("btn-active-light-info");
        checkboxLabel.classList.add("btn-active-light-danger");
        checkboxLabel.classList.remove("btn-active-light-primary");
        checkboxLabel.classList.remove("btn-light-primary");
        checkboxLabel.innerHTML = `
                        <span className="svg-icon svg-icon-muted svg-icon-2hx">
                            <i className="ki-duotone ki-cross-circle fs-2x">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                        </span>
                        <span className="d-block fw-semibold text-start">
                            <span className="text-gray-900 fw-semibold fs-6 p-1">
                                ${String.fromCharCode(65 + index)}. ${
          proposition.proposition_text
        }
                            </span>
                        </span>
                    `;
      }
      checkboxLabel.style.userSelect = "text";
    }
  });
};

export const updateExamPropositionLabels = (selectedAnswers, question) => {
  const selectedPropositionIds = selectedAnswers[question.id] || [];
  question.propositions.forEach((proposition) => {
    const checkboxLabel = document.getElementById(
      `kt_radio_option1_${proposition.id}`
    );
    checkboxLabel.style.pointerEvents = "none";

    if (checkboxLabel) {
      const isChecked = selectedPropositionIds.includes(proposition.id);

      if (isChecked && proposition.is_correct) {
        checkboxLabel.classList.remove("btn-active-light-info");
        checkboxLabel.classList.add("btn-active-light-primary");
      } else if (!isChecked && proposition.is_correct) {
        checkboxLabel.classList.add("btn-light-primary");
        checkboxLabel.classList.remove("btn-active-light-danger");
      } else if (isChecked && !proposition.is_correct) {
        checkboxLabel.classList.remove("btn-active-light-info");
        checkboxLabel.classList.add("btn-active-light-danger");
        checkboxLabel.classList.remove("btn-active-light-primary");
        checkboxLabel.classList.remove("btn-light-primary");
      }
    }
  });
};

export const updateAnswerCounts = (
  questionPropsIds,
  questions,
  selectedPropositionIds,
  setNewSessionStats,
  setEvaluation,
  questionIndexClasses,
  setQuestionIndexClasses
) => {
  const numCorrect = countCorrectPropositions(
    selectedPropositionIds,
    questions
  );
  const numIncorrect = countIncorrectPropositions(
    selectedPropositionIds,
    questions
  );
  const numPropsCorrects = countCorrectPropositions(
    questionPropsIds,
    questions
  );

  const calculatePartielNegatif = () =>
    numIncorrect > 0 ? 0 : numCorrect / numPropsCorrects;
  const calculatePartielPositif = () => Math.max(numCorrect - numIncorrect, 0);
  const calculateToutOuRien = () =>
    numCorrect === numPropsCorrects && numIncorrect === 0 ? 1 : 0;

  setNewSessionStats((prevStats) => ({
    ...prevStats,
    partielNegatif: prevStats.partielNegatif + calculatePartielNegatif(),
    partielPositif: prevStats.partielPositif + calculatePartielPositif(),
    toutOuRien: prevStats.toutOuRien + calculateToutOuRien(),
  }));

  const selectedWrong = selectedPropositionIds.some((propId) =>
    questions.propositions.find(
      (prop) => prop.id === propId && !prop.is_correct
    )
  );
  const allSelectedCorrect = selectedPropositionIds.every((propId) =>
    questions.propositions.find((prop) => prop.id === propId && prop.is_correct)
  );
  const unselectedCorrect = questions.propositions.some(
    (prop) => prop.is_correct && !selectedPropositionIds.includes(prop.id)
  );
  const updatedClasses = { ...questionIndexClasses };
  let newEvaluation = "";
  if (selectedPropositionIds.length === 0) {
    updatedClasses[questions.id] = "bg-light-dark";

    setQuestionIndexClasses(updatedClasses);
    newEvaluation = "NO_ANSWER";
  } else if (selectedWrong) {
    setNewSessionStats((prevStats) => ({
      ...prevStats,
      fauxCount: prevStats.fauxCount + 1,
    }));
    updatedClasses[questions.id] = "btn-bg-danger";
    setQuestionIndexClasses(updatedClasses);
    newEvaluation = "WRONG";
  } else if (allSelectedCorrect && !unselectedCorrect) {
    setNewSessionStats((prevStats) => ({
      ...prevStats,
      justeCount: prevStats.justeCount + 1,
    }));
    newEvaluation = "TRUE";
    updatedClasses[questions.id] = "btn-bg-success";
    setQuestionIndexClasses(updatedClasses);
  } else if (allSelectedCorrect && unselectedCorrect) {
    setNewSessionStats((prevStats) => ({
      ...prevStats,
      partJusteCount: prevStats.partJusteCount + 1,
    }));
    newEvaluation = "PART_TRUE";
    updatedClasses[questions.id] = "btn-bg-warning";
    setQuestionIndexClasses(updatedClasses);
  }
  // Update the marksByPartCours state
  setNewSessionStats((prevStats) => {
    const updatedMarks = { ...prevStats.marksByPartCours };
    if (updatedMarks.hasOwnProperty(questions.part_cours)) {
      updatedMarks[questions.part_cours].mark += calculateToutOuRien();
      updatedMarks[questions.part_cours].total += 1;
    } else {
      updatedMarks[questions.part_cours] = {
        mark: calculateToutOuRien(),
        total: 1,
      };
    }
    return { ...prevStats, marksByPartCours: updatedMarks };
  });
  setEvaluation(newEvaluation);
  return newEvaluation;
};

export const calculateExamScore = (
  selectedAnswers,
  questions,
  setNewSessionStats
) => {
  questions.forEach((question) => {
    const selectedPropositionIds = selectedAnswers[question.id];
    if (selectedPropositionIds) {
      const questionPropsIds = [];
      question.propositions.forEach((proposition) => {
        if (proposition.is_correct) {
          questionPropsIds.push(proposition.id);
        }
      });
      const numCorrect = countCorrectPropositions(
        selectedPropositionIds,
        question
      );
      const numIncorrect = countIncorrectPropositions(
        selectedPropositionIds,
        question
      );
      const numPropsCorrects = countCorrectPropositions(
        questionPropsIds,
        question
      );

      const calculatePartielNegatif = () =>
        numIncorrect > 0 ? 0 : numCorrect / numPropsCorrects;
      const calculatePartielPositif = () =>
        Math.max(numCorrect - numIncorrect, 0);
      const calculateToutOuRien = () =>
        numCorrect === numPropsCorrects && numIncorrect === 0 ? 1 : 0;

      setNewSessionStats((prevStats) => ({
        ...prevStats,
        partielNegatif: prevStats.partielNegatif + calculatePartielNegatif(),
        partielPositif: prevStats.partielPositif + calculatePartielPositif(),
        toutOuRien: prevStats.toutOuRien + calculateToutOuRien(),
      }));
      const selectedWrong = selectedPropositionIds.some((propId) =>
        question.propositions.find(
          (prop) => prop.id === propId && !prop.is_correct
        )
      );
      const allSelectedCorrect = selectedPropositionIds.every((propId) =>
        question.propositions.find(
          (prop) => prop.id === propId && prop.is_correct
        )
      );
      const unselectedCorrect = question.propositions.some(
        (prop) => prop.is_correct && !selectedPropositionIds.includes(prop.id)
      );

      if (selectedWrong) {
        setNewSessionStats((prevStats) => ({
          ...prevStats,
          fauxCount: prevStats.fauxCount + 1,
        }));
      } else if (allSelectedCorrect && !unselectedCorrect) {
        setNewSessionStats((prevStats) => ({
          ...prevStats,
          justeCount: prevStats.justeCount + 1,
        }));
      } else if (allSelectedCorrect && unselectedCorrect) {
        setNewSessionStats((prevStats) => ({
          ...prevStats,
          partJusteCount: prevStats.partJusteCount + 1,
        }));
      }

      // Update the marksByPartCours state
      setNewSessionStats((prevStats) => {
        const updatedMarks = { ...prevStats.marksByPartCours };
        if (updatedMarks.hasOwnProperty(question.part_cours)) {
          updatedMarks[question.part_cours].mark += calculateToutOuRien();
          updatedMarks[question.part_cours].total += 1;
        } else {
          updatedMarks[question.part_cours] = {
            mark: calculateToutOuRien(),
            total: 1,
          };
        }
        return { ...prevStats, marksByPartCours: updatedMarks };
      });
    }
  });
};

export const countCorrectPropositions = (selectedPropositionIds, questions) => {
  return selectedPropositionIds.filter((propId) =>
    questions.propositions.find((prop) => prop.id === propId && prop.is_correct)
  ).length;
};

export const countIncorrectPropositions = (
  selectedPropositionIds,
  questions
) => {
  return selectedPropositionIds.filter((propId) =>
    questions.propositions.find(
      (prop) => prop.id === propId && !prop.is_correct
    )
  ).length;
};

export const startNewSession = (
  newSessionStats,
  setNewSessionStats,
  setOldSessionStats
) => {
  setOldSessionStats(newSessionStats);
  setNewSessionStats({
    fauxCount: 0,
    justeCount: 0,
    partJusteCount: 0,
    partielNegatif: 0,
    partielPositif: 0,
    toutOuRien: 0,
    marksByPartCours: {},
  });
};

export const displayEvaluationMessage = (
  response,
  evaluation,
  propositions
) => {
  const oldEval = response?.old_answer?.evaluation || null;
  const currentEval = evaluation;

  let evaluationKey;

  if (oldEval) {
    evaluationKey = `${oldEval}_${currentEval}`;
  } else {
    evaluationKey = currentEval;
  }

  const message = evaluationMessages.answers[evaluationKey];

  if (message) {
    toast(
      (t) => (
        <span
          className="w-200"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div>
            <span className="fw-bold">{message.text}</span> <br />
            <i>{message.message}</i>
            {response?.propositions && (
              <ul
                style={{
                  display: "flex",
                  listStyleType: "none",
                  margin: 0,
                  padding: 0,
                  gap: "8px",
                }}
              >
                La derniere reponse :
                {Array.isArray(response?.propositions) &&
                  response?.propositions.length > 0 &&
                  response?.propositions.map((selectedPropId, index) => {
                    const propIndex = propositions.findIndex(
                      (prop) => prop.id === selectedPropId
                    );

                    if (propIndex !== -1) {
                      const letter = String.fromCharCode(65 + propIndex);
                      return (
                        <li key={selectedPropId} style={{ marginRight: "8px" }}>
                          {letter}
                        </li>
                      );
                    }
                    return null;
                  })}
              </ul>
            )}
          </div>

          <button
            className="btn btn-icon btn-sm btn-active-color-primary"
            onClick={() => toast.dismiss(t.id)}
            style={{ marginLeft: "8px" }}
          >
            <i className="ki-duotone ki-cross text-danger fs-1">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </button>
        </span>
      ),
      {
        icon: `${message.emoji}`,
        duration: 10000,
        position: "bottom-right",
      }
    );
  }
};
