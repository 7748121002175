import React, { useEffect, useState } from "react";
import { deleteComment, postComment, updateComment } from "features/medecine";
import { useDispatch } from "react-redux";
import sanitizeHtml from "sanitize-html";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
const Comment = ({ currentQuestion, isCommentOpen, toggleCommentDrawer }) => {
  const dispatch = useDispatch();

  const [content, setContent] = useState("");
  const [editCommentId, setEditCommentId] = useState(null);
  const [editedContent, setEditedContent] = useState("");

  const handleEdit = (commentId, initialContent) => {
    setEditCommentId(commentId);
    setEditedContent(initialContent);
  };

  const handleCancel = () => {
    setEditCommentId(null);
    setEditedContent("");
  };

  const handleCommentChange = (e) => {
    setContent(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postComment({ question_id: currentQuestion.id, content }));
    setContent("");
  };

  const handleSubmitEdit = (commentId) => {
    if (editedContent.trim() !== "") {
      dispatch(updateComment({ commentId, editedContent }));
      setEditCommentId(null);
    }
  };

  const handleDelete = (commentId) => {
    showSwalWithLink(commentId);
    setEditCommentId(null);
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const now = new Date();

    const timeDifference = now - date;

    if (timeDifference < 60000) {
      return "À l'instant";
    } else if (timeDifference < 3600000) {
      const minutes = Math.floor(timeDifference / 60000);
      return `${minutes} minute${minutes === 1 ? "" : "s"} auparavant`;
    } else if (timeDifference < 86400000) {
      const hours = Math.floor(timeDifference / 3600000);
      return `${hours} heure${hours === 1 ? "" : "s"} auparavant`;
    } else {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return date.toLocaleDateString("fr-FR", options);
    }
  }
  function sanitizeCommentContent(content) {
    // Define allowed HTML tags and attributes
    const allowedTags = ["a", "p", "strong", "em", "br"];
    const allowedAttributes = {
      a: ["href", "title"],
    };

    // Sanitize the comment content
    return sanitizeHtml(content, {
      allowedTags,
      allowedAttributes,
    });
  }

  const showSwalWithLink = (commentId) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      text: "Voulez-vous vraiment supprimer ce commentaire ?",
      icon: "warning",
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Supprimer",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "btn btn-danger mx-2",
        cancelButton: "btn btn-success",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteComment({ commentId }));
      }
    });
  };
  const [drawerWidth, setDrawerWidth] = useState(600);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setDrawerWidth("80vw"); // 80% of viewport width on smaller screens
      } else {
        setDrawerWidth(600); // default size
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Drawer
      open={isCommentOpen}
      onClose={toggleCommentDrawer}
      direction="right"
      style={{ width: drawerWidth }}
    >
      <div className="card shadow-none border-0 rounded-0">
        <div className="card-header" id="kt_activities_header">
          <h3 className="card-title fw-bold text-dark">Commentaires</h3>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-sm btn-icon btn-active-light-primary me-n5"
              onClick={toggleCommentDrawer}
            >
              <i className="ki-duotone ki-cross fs-1">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </button>
          </div>
        </div>
        <div className="card-body position-relative" id="kt_activities_body">
          <div
            id="kt_activities_scroll"
            className="position-relative scroll-y me-n5 pe-5"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-wrappers="#kt_activities_body"
            data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer"
            data-kt-scroll-offset="5px"
          >
            <div className="timeline">
              {currentQuestion?.comments?.length === 0 && (
                <div className="timeline-item">
                  <div className="timeline-content mb-3 mt-n1">
                    <div className="fs-5 fw-semibold mb-2 text-gray-500">
                      Commencez votre premier commentaire
                    </div>
                  </div>
                </div>
              )}
              {currentQuestion?.comments?.map((comment) => (
                <div className="timeline-item" key={comment.id}>
                  <div className="timeline-line w-40px"></div>
                  <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                    <div className="symbol-label bg-light">
                      <i className="ki-duotone ki-message-text-2 fs-2 text-gray-500">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                    </div>
                  </div>
                  <div className="timeline-content mb-3 mt-n1">
                    <div className="pe-3 mb-5">
                      <div className="fs-5 fw-semibold mb-2">
                        {editCommentId === comment.id ? (
                          <div className="d-flex align-items-center">
                            <div className="position-relative w-100">
                              <textarea
                                type="text"
                                className="form-control form-control-solid border ps-5"
                                rows="3"
                                name="search"
                                data-kt-autosize="true"
                                placeholder="Écrivez votre commentaire..."
                                value={editedContent}
                                onChange={(e) =>
                                  setEditedContent(e.target.value)
                                }
                              ></textarea>
                              <div className="position-absolute top-0 end-0 translate-middle-x mt-1">
                                <button
                                  className="btn btn-icon btn-sm btn-color-primary btn-active-color-primary w-25px p-0"
                                  disabled={!editedContent}
                                  onClick={() => handleSubmitEdit(comment.id)}
                                >
                                  <i className="ki-duotone ki-send fs-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <span
                              className="fs-3 fw-semibold mb-2"
                              dangerouslySetInnerHTML={{
                                __html: sanitizeCommentContent(comment.content),
                              }}
                            ></span>
                          </>
                        )}
                      </div>
                      <div className="d-flex align-items-center mt-1 fs-6">
                        <div className="">
                          <span className="text-muted me-2 fs-7">
                            {formatTimestamp(comment.created_at)} par
                          </span>
                          {comment.user}
                        </div>
                        <div className="">
                          {editCommentId === comment.id ? (
                            <button
                              type="button"
                              className="btn btn-icon btn-sm btn-color-danger btn-active-color-danger w-25px p-0"
                              onClick={() =>
                                handleCancel(comment.id, comment.content)
                              }
                            >
                              <i className="ki-duotone ki-cross-circle fs-6">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </button>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-icon btn-sm btn-color-success btn-active-color-success w-25px p-0"
                                onClick={() =>
                                  handleEdit(comment.id, comment.content)
                                }
                              >
                                <i className="ki-duotone ki-pencil fs-2">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                              </button>
                              {/* Delete Icon */}
                              <button
                                type="button"
                                className="btn btn-icon btn-sm btn-color-danger btn-active-color-danger w-25px p-0"
                                onClick={() => handleDelete(comment.id)}
                              >
                                <i className="ki-duotone ki-trash fs-2">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                  <span className="path3"></span>
                                  <span className="path4"></span>
                                  <span className="path5"></span>
                                </i>
                              </button>
                              {/* Delete Icon */}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="overflow-auto pb-0">
                      <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="card-footer" id="kt_activities_footer">
          <div className="d-flex align-items-center">
            <div className="position-relative w-100">
              <form onSubmit={handleSubmit}>
                <textarea
                  type="text"
                  className="form-control form-control-solid border ps-5"
                  rows="3"
                  name="search"
                  data-kt-autosize="true"
                  placeholder="Écrivez votre commentaire..."
                  value={content}
                  onChange={handleCommentChange}
                ></textarea>
                <div className="position-absolute top-0 end-0 translate-middle-x mt-1">
                  <button
                    type="submit"
                    className="btn btn-icon btn-sm btn-color-primary btn-active-color-primary w-25px p-0"
                    disabled={!content}
                  >
                    <i className="ki-duotone ki-send fs-2">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default Comment;
