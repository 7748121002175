import { reset_password, resetRegistered } from "features/auth";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Auth from "components/auth/Auth";
import useRecaptcha from "hooks/useRecaptcha";

const ResetPassword = () => {
  const dispatch = useDispatch();
  useRecaptcha("user_reset_password");
  const recaptcha_token = useSelector((state) => state.recaptcha.token);
  const { loading, isAuthenticated, registered, error } = useSelector(
    (state) => state.user
  );
  const [passwordState, setPasswordState] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
  });

  useEffect(() => {
    if (registered) dispatch(resetRegistered());
  }, [dispatch, registered]);

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (recaptcha_token) {
      dispatch(reset_password({ email }));
    }
    setPasswordState(true);
  };

  if (isAuthenticated) return <Navigate to="/dashboard" />;

  return (
    <Auth
      title="Elmajor | Réinitialisation du mot de passe"
      content="reset password"
    >
      {passwordState && !loading && (
        <div className="alert alert-dismissible bg-light-primary border border-primary d-flex flex-column flex-sm-row mb-10 position-fixed top-0 start-0 p-3 z-index-3">
          <i className="ki-duotone ki-search-list fs-2hx text-success me-4 mb-5 mb-sm-0">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>

          <div className="d-flex flex-column pe-0 pe-sm-10">
            <span>
              Un email a été envoyé, veuillez vérifier votre boîte de réception
              pour <br />
              réinitialiser votre mot de passe
            </span>
          </div>
          <button
            type="button"
            className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
            data-bs-dismiss="alert"
          >
            <i className="ki-duotone ki-cross fs-1 text-success">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </button>
        </div>
      )}
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 mt-20 pb-30">
        <div className="d-flex flex-center flex-column flex-lg-row-fluid mt-20 pb-30">
          <div className="w-lg-500px p-10">
            <form className="form w-100" onSubmit={onSubmit}>
              <div className="text-center mb-10">
                <h1 className="text-dark fw-bolder mb-3">
                  Mot de passe oublié ?
                </h1>
                <div className="text-gray-500 fw-semibold fs-6">
                  Entrez votre e-mail pour réinitialiser votre mot de passe.
                </div>
              </div>
              <div className="fv-row mb-8">
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  autoComplete="on"
                  className="form-control bg-transparent"
                  onChange={onChange}
                  value={email}
                  required
                />
                {error && error.email && (
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div
                      data-field="confirm-password"
                      data-validator="identical"
                    >
                      {error.email}
                    </div>
                  </div>
                )}
                {/* {error && <div className="fv-plugins-message-container invalid-feedback">
                                    <div data-field="confirm-password" data-validator="identical">
                                        {error}
                                    </div>
                                </div>} */}
              </div>
              <div className="d-grid mb-10">
                <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                  {loading ? (
                    <button disabled className="btn btn-primary">
                      <span className="">
                        S'il vous plaît, attendez...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  ) : (
                    <>
                      <button type="submit" className="btn btn-primary">
                        <span className="indicator-label">Envoyer</span>
                      </button>
                      <Link to="/login" className="btn btn-light">
                        Annuler
                      </Link>
                    </>
                  )}
                </div>
              </div>
              <div className="text-gray-500 text-center fw-semibold fs-6">
                Pas encore membre?
                <Link to="/register" className="link-primary">
                  {" "}
                  S'inscrire
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Auth>
  );
};

export default ResetPassword;
