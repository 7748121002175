import React from "react";

import { useSelector } from "react-redux";

const Invoice = () => {
  const { subscription } = useSelector((state) => state.subscription);
  const { user } = useSelector((state) => state.user);

  return (
    <div className="card">
      <div className="card-body py-20">
        <div className="mw-lg-950px mx-auto w-100">
          <div className="d-flex justify-content-between flex-column flex-sm-row mb-19">
            <h4 className="fw-bolder text-gray-800 fs-2qx pe-5 pb-7">
              BON POUR
            </h4>
          </div>
          <div className="border-bottom pb-12">
            <div
              className="d-flex flex-row-fluid bgi-no-repeat bgi-position-x-center bgi-size-cover card-rounded h-150px h-lg-250px mb-lg-20"
              style={{
                backgroundImage: "url(assets/media/misc/pattern-4.jpg)",
              }}
            ></div>
            <div className="d-flex justify-content-between flex-column flex-md-row">
              <div className="flex-grow-1 pt-8 mb-13">
                <div className="table-responsive border-bottom mb-14">
                  <table className="table">
                    <thead>
                      <tr className="border-bottom fs-6 fw-bold text-muted text-uppercase">
                        <th className="min-w-175px pb-9">Plan d'abonnement</th>
                        <th className="min-w-70px pb-9 text-end">
                          Date d'expiration
                        </th>
                        <th className="min-w-80px pb-9 text-end">Montant</th>
                        <th className="min-w-100px pe-lg-6 pb-9 text-end">
                          Remise
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscription && (
                        <tr className="fw-bold text-gray-700 fs-5 text-end">
                          <td className="d-flex align-items-center pt-11">
                            <i className="fa fa-genderless text-danger fs-1 me-2"></i>
                            {subscription.plan_title}
                          </td>
                          <td className="pt-11">
                            {subscription.expiration_date}
                          </td>
                          <td className="pt-11">{subscription.price} DZD</td>
                          <td className="pt-11 fs-5 pe-lg-6 text-dark fw-bolder">
                            {subscription.discount
                              ? subscription.discount
                              : "0.00 DZD"}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex flex-column mw-md-300px w-100">
                  <div className="fw-semibold fs-5 mb-3 text-dark00">
                    Paiement CCP/BaridiMob au compte
                  </div>
                  <div className="d-flex flex-stack text-gray-800 mb-3 fs-6">
                    <div className="fw-semibold pe-5">Titulaire du compte:</div>
                    <div className="text-end fw-norma">Barclays UK</div>
                  </div>
                  <div className="d-flex flex-stack text-gray-800 mb-3 fs-6">
                    <div className="fw-semibold pe-5">CCP:</div>
                    <div className="text-end fw-norma">1234567890934</div>
                  </div>
                  <div className="d-flex flex-stack text-gray-800 fs-6">
                    <div className="fw-semibold pe-5">RIP:</div>
                    <div className="text-end fw-norma">BARC0032UK</div>
                  </div>
                </div>
              </div>
              <div className="border-end d-none d-md-block mh-450px mx-9"></div>
              <div className="text-end pt-10">
                <div className="fs-3 fw-bold text-muted mb-3">
                  Total à payer
                </div>
                <div className="fs-xl-2x fs-2 fw-bolder">
                  {subscription && subscription.price} DZD
                </div>
                <div className="border-bottom w-100 my-7 my-lg-16"></div>
                <div className="text-gray-600 fs-6 fw-semibold mb-3">
                  Destinataire.
                </div>
                <div className="fs-6 text-gray-800 fw-semibold mb-8">
                  {user.name}
                </div>
                <div className="text-gray-600 fs-6 fw-semibold mb-3">
                  Date d'échéance
                </div>
                <div className="fs-6 text-gray-800 fw-semibold">
                  {subscription && subscription.activation_date}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
