import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setRecaptchaToken } from 'features/recaptchaSlice';

const useRecaptcha = (action) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const executeRecaptcha = () => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6LcAko0nAAAAAHlx2WTDrALrbNd4DCbK6MfhN1GB', { action })
          .then(token => {
            dispatch(setRecaptchaToken(token));
          });
      });
    };

    if (typeof window.grecaptcha === 'undefined') {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?render=6LcAko0nAAAAAHlx2WTDrALrbNd4DCbK6MfhN1GB';
      script.onload = executeRecaptcha;
      document.body.appendChild(script);
    } else {
      executeRecaptcha();
    }
  }, [action, dispatch]);

  return null; // You can return something meaningful if needed
};

export default useRecaptcha;
