import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // or any other storage provider you prefer

import userReducer from "features/auth";
import subscriptionReducer from "features/subscription";
import medecineReducer from "features/medecine";
import homeReducer from "features/homeSlice";
import recaptchaReducer from 'features/recaptchaSlice';
import {combineReducers} from 'redux';

const persistConfig = {
  key: 'root', // key for the persistor in local storage
  storage, // storage provider
};

const rootReducer = combineReducers({
  user: userReducer,
  subscription: subscriptionReducer,
  medecine: medecineReducer,
  home: homeReducer,
  recaptcha: recaptchaReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
