import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setPageVisible } from "features/homeSlice";
import { getExams } from "features/medecine";

const Examen = ({
  setSelectedExamSession,
  setUniqueSessionsArray,
  uniqueSessionsArray,
  resetSelections,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { subscription } = useSelector((state) => state.subscription);
  const { loading, exams, examQuestions } = useSelector(
    (state) => state.medecine
  );
  const [questionsReady] = useState(false);

  useEffect(() => {
    dispatch(getExams());
    const uniqueSessions = new Set(exams?.map((exam) => exam.session));
    setUniqueSessionsArray(Array.from(uniqueSessions));
  }, [dispatch]);

  const displayExams = (session) => {
    dispatch(setPageVisible({ page: "exams", visible: true }));

    dispatch(setSelectedExamSession(session));

    if (window.innerWidth <= 767) {
      document.getElementById("kt_aside").classList.remove("drawer-on");
    }
  };

  if (questionsReady && examQuestions && !examQuestions.message) {
    navigate("/exam");
    return null;
  }

  return (
    <div
      className="tab-pane scroll fade"
      id="kt_aside_nav_tab_examen"
      role="tabpanel"
    >
      <div className="my-5">
        {loading ? (
          <span className="indicator-progress">
            Veuillez patienter...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <ol className="breadcrumb fs-6 fw-semibold mx-10">
            <li className="breadcrumb-item">
              <button className="btn p-2" onClick={resetSelections}>
                Examens
              </button>
            </li>
          </ol>
        )}
        {subscription &&
          subscription.is_active &&
          subscription.activation_code_fields.plan === "RESIDANAT" && (
            <div className="mt-12 m-5">
              {uniqueSessionsArray &&
                uniqueSessionsArray.map((session, index) => {
                  return (
                    <div
                      className="d-flex align-items-center mb-7 cursor-pointer border rounded bg-light-info px-5 py-3 border-1 border-primary"
                      onClick={() => displayExams(session)}
                      key={index}
                    >
                      <div className="symbol symbol-50px">
                        <span className="symbol-label bg-light-info">
                          <i className="ki-duotone ki-abstract-26 fs-2x text-secondary">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="text-gray-800 text-hover-primary fw-bold">
                          {session}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        {subscription &&
          subscription.is_active &&
          subscription.activation_code_fields.plan !== "RESIDANAT" && (
            <div className="mt-12 m-5">
              {uniqueSessionsArray &&
                uniqueSessionsArray.map((session, index) => {
                  return (
                    <button
                      className="btn btn-outline btn-outline-primary btn-outline-dashed d-flex align-items-center bg-light-secondary rounded p-5 mb-7 w-100"
                      onClick={() => displayExams(session)}
                      key={index}
                    >
                      <span className="svg-icon text-secondary me-5">
                        <i className="ki-duotone ki-abstract-26 fs-1 text-secondary">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      </span>
                      <div className="flex-grow-1 me-2">
                        <span className="text-muted fw-semibold d-block">
                          {session}
                        </span>
                      </div>
                    </button>
                  );
                })}
            </div>
          )}
      </div>
      {!subscription && (
        <div className="mx-5">
          <div className="text-center pt-10 mb-20">
            <p className="text-gray-400 fs-4 fw-semibold mb-10">
              Veuillez vous abonner pour consulter les examens
            </p>
          </div>
          <div className="text-center px-4">
            <img
              src="assets/media/illustrations/sigma-1/18.png"
              alt=""
              className="mw-100 mh-300px"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Examen;
