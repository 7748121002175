import { FacebookProvider, CustomChat } from "react-facebook";

const FacebookMsg = () => {
  const isMinimized = true;
  return (
    <FacebookProvider appId="444357458655884" chatSupport>
      <CustomChat pageId="101165002525208" minimized={isMinimized.toString()} />
    </FacebookProvider>
  );
};

export default FacebookMsg;
